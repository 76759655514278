import Vue from 'vue'
import Vuex from 'vuex'
import debounce from 'lodash/debounce'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'
import initialState from './initial-state'
import _merge from 'lodash.merge'

const software = {
  namespaced: true,
  state: initialState,
  mutations,
  actions,
  getters
}

Vue.use(Vuex)

let _store = new Vuex.Store()

_store.registerModule('software', software)

//restore previous state
_store.commit('software/restoreState')

export function updateStore(state) {
  const currentState = localStorage.getItem('mymachine_state') ? JSON.parse(localStorage.getItem('mymachine_state')) : {}

  const reduced = { //store only non sensitive data
    language: state.software.language,
    region: state.software.region
  }
  localStorage.setItem('mymachine_state', JSON.stringify(_merge(currentState, reduced)))
}

let debouncedUpdate = debounce(updateStore, 1000)

// subscribe to save store on change
_store.subscribe((mutation, state) => {
  debouncedUpdate(state)
})

export default _store
