module.exports = {
  CONTENT_API: {
    TRANSLATIONS: 'public/concepts/dealerportal/{path}',
    REGION_TRANSLATIONS: 'public/translations/dealer-portal/{region}/all',
    SITEMAP: 'public/sitemap'
  },
  API: {
    PRODUCTS_DETAILS: 'product/{serial}/details',
    FAULT_CODES_LIST: 'connected/v3/{serial}/fault_codes',
    FAULT_CODES_AUTOCOMPLETE: 'product/fault_codes',
    MACHINE_PASSWORD: 'product/{serial}/password',
    MACHINE_PASSWORD_UPDATE: 'product/{serial}/password/{panel}',
    MACHINE_DATA: 'connected/v3/{serial}',
    LOCATION_DATA: 'connected/v3/{serial}/location',
    MACHINE_SOFTWARE: 'product/{serial}/software/versions',
    PAGE: '.rest/delivery/owner/portal/pages/{page_path}',
    PAGENAV: '.rest/delivery/owner/portal/pagenav/{region}',
    MACHINE_HISTORY: 'machine_history/{serial}/sessions',
    CONTROLLERS_DATA: 'connected/v3/{serial}/controllers',
    FAQ_SECTIONS: 'content/faq/{directory}',
    FAQ_CONTENT: 'content/faq/{directory}/section/{sectionKey}',
    WARRANTY_INCLUSIONS: 'equipment/{serial}/warranty_inclusions',
    TROUBLESHOOTING_SESSION_START: 'troubleshooting/{serial}/start/{guide_id}',
    TROUBLESHOOTING_SESSION_RESUME: 'troubleshooting/sessions/{session_id}/resume',
    TROUBLESHOOTING_GUIDES_LIST: 'troubleshooting/guides/serial/{serial}',
    TROUBLESHOOTING_SESSION_LIST: 'troubleshooting/sessions/serial/{serial}',
    TROUBLESHOOTING_SETTINGS: 'troubleshooting/settings/serial/{serial}',
    TROUBLESHOOTING_SESSION_SUMMARY: 'troubleshooting/sessions/summary/{session_id}',
    TROUBLESHOOTING_SESSION_SUMMARY_PDF: 'troubleshooting/sessions/summary/{session_id}/pdf',
    SERVICE_LIBRARY_BULLETINS: 'documents/bulletins/{serial}',
    SERVICE_LIBRARY_LETTERS: 'documents/service_letters/{field_mod_code}',
    SERVICE_LIBRARY_DOCUMENTS: 'documents',
    SERVICE_LIBRARY_MANUALS: 'documents/manuals/{serial}',
    SERVICE_LIBRARY_DOCUMENT_DOWNLOAD: 'documents/{document_id}',
    SERVICE_MAINTENANCE_DETAILS: 'product/{serial}/service_details',
    SCHEMATICS_LIST: 'product/{serial}/schematics-v2',
    SCHEMATICS_DETAILS: 'product/{serial}/schematics-v2/{schematic_id}',
    AVAILABLE_REGIONS: 'business_units',
    FEEDBACK_SUBSCRIPTIONS: 'service/usb/subscriptions',
    TROUBLESHOOTING_GUIDE: 'service/troubleshooting/guides',
    LATEST_SOFTWARE: 'software/{software}/latest',
    FEATURE: 'feature/{feature}',
    FEATURES: 'feature',
    OPTIONS_LIST: 'assets/{serial}/options',
    SERVICE_FIELD_MODIFICATIONS: 'assets/{serial}/field_modifications',
    WARRANTY_PLANS: 'assets/{serial}/warranty_plans'
  },
  MAGNOLIA_AUTHOR: {
    TEMPLATE_DEFINITIONS: '.rest/template-definitions/v1/',
    PAGE: '.rest/delivery/owner/portal/pages/{page_path}',
    PAGENAV: '.rest/delivery/owner/portal/pagenav/{region}',
    CATEGORY: '.rest/delivery/dp/category/dealer-portal/{category}@nodes'
  }
}
