import axios from '@/axios'
import api from '@/api'

const CancelToken = axios.CancelToken
let cancelFn = {}

export const apiService = {
  fetchPage,
  fetchPageNav,
  fetchAvailableRegions,
  fetchSoftware
}

function fetchPage(path, region, lang) {
  const regionLowerCase = region.toLowerCase()
  if (typeof cancelFn['fetchPage'] === 'function') cancelFn['fetchPage']('isCancelled')
  if (typeof cancelFn['fetchTemplateDefinition'] === 'function') cancelFn['fetchTemplateDefinition']('isCancelled')

  return axios.get(api.endpoints.API.PAGE.replace('{page_path}', `owner-portal/${regionLowerCase}/${path}`), {
    params: {lang},
    cancelToken: new CancelToken(function executor(cancelerFn) {
      cancelFn['fetchPage'] = cancelerFn
    })
  })
}

function fetchPageNav(region, lang) {
  const regionLowerCase = region.toLowerCase()
  if (typeof cancelFn['fetchPageNav'] === 'function') cancelFn['fetchPageNav']('isCancelled')

  return axios.get(api.endpoints.API.PAGENAV.replace('{region}', regionLowerCase), {
    params: {lang},
    cancelToken: new CancelToken(function executor(cancelerFn) {
      cancelFn['fetchPageNav'] = cancelerFn
    })
  })
}

function fetchAvailableRegions() {
  return axios.get(api.endpoints.API.AVAILABLE_REGIONS)
}

function fetchSoftware(software, locale) {
  return axios.get(api.endpoints.API.LATEST_SOFTWARE.replace('{software}', software), {
    params: {locale}
  })
}
