<template>
  <Component
    :is="config.componentMappings[componentTemplateId]"
    v-if="componentTemplateId"
    v-bind="componentProps"
    class="clearfix"
  />
</template>

<script>
import {TemplateAnnotations} from '@magnolia/template-annotations'
import {EditorContextHelper, insertComments, getProps} from './helper'
import {inEditorEdit, inLocalhostEditor} from './advanced-helper'

export default {
  name: 'EditableComponent',
  inject: ['config', 'templateAnnotations', 'templateDefinitions', 'pageTemplateId'],
  props: ['content'],
  data() {
    return {
      componentTemplateId: this.content['mgnl:template']
    }
  },
  computed: {
    componentProps() {
      return getProps(this.content)
    }
  },
  mounted() {
    if (!this.content || !EditorContextHelper.isRenderingAnnotation()) {
      return
    }
    if (this.templateAnnotations.value && this.templateAnnotations.value[this.renderingContent['@path']]) {
      const commentOpen = this.templateAnnotations.value[this.renderingContent['@path']]
      insertComments(this.$el, commentOpen, '/cms:component')
    } else { // FIXED WAY
      const templateDefinition = this.templateDefinitions[this.componentTemplateId]
      if ((inEditorEdit || inLocalhostEditor) && this.content.editable !== 'false') {
        const commentOpen = TemplateAnnotations.getComponentCommentString(this.content, templateDefinition)
        insertComments(this.$el, commentOpen, '/cms:component')
      }
    }
  }
}
</script>
