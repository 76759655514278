import api from '@/api'
import slpage from './responses/magnolia/sl-page'
import pagenav from './responses/magnolia/pagenav'

export default function (server) {
  server.get(location.protocol + api.getEndpoint(api.endpoints.API.PAGE, {page_path: ':root/:region/:page'}), (schema, request) => {
    let {page} = request.params
    slpage.title = page
    return slpage
  }, {timing: 500})

  server.get(location.protocol + api.getEndpoint(api.endpoints.API.PAGENAV, {region: ':region'}), () => {
    return pagenav
  })

  server.get(location.protocol + api.endpoints.API.AVAILABLE_REGIONS, () => {
    return {business_units: ['NA', 'EMEA', 'ALAO']}
  })

  server.get(location.protocol + api.getEndpoint(api.endpoints.API.LATEST_SOFTWARE, {software: ':software'}), (schema, request) => {
    let {software} = request.params
    return [
      {
        'software_type': software,
        'name': software + ' 2.02 Installer.exe',
        'brand': 'Bobcat NA',
        'version': '2.02',
        'instructions': 'No change to file extension',
        'id': '01I4V2QH7FO6AFEPJG4ZDYBXGHT2WMW6F6',
        'release_date': '2022-02-22T22:22:22Z',
        'locale': 'en-US',
        'type': 'software',
        'download_link': 'https://dibh-dev-dxp-software-files.s3.amazonaws.com/01I4V2QH7FO6AFEPJG4ZDYBXGHT2WMW6F6?AWSAccessKeyId=ASIAWSTXIYHSNF7DII4E&Expires=1646133795&Signature=5GOOFwVwqHXN5qn5gkRxbTZ0IFU%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQCD9zsmtAbbAnyJnhlvmLjdUjdzNOIBQmrtCBkKTpVTYwIhAJTIqNkWP0f4JIcyLIhsDqXgVNUNeR5mxSvO8lGKJiFlKrMCCBMQABoMNDUyMjk1NDQyOTE2Igz4ud%2Bv4polYAAoc7YqkAKNmOyWImQApVZgA9lVwpiX%2FG6qJU1YV7dDsICnnGQ%2FbdAYUJJFrkt1gEhDCHxqRrNEFyYQOjD9%2FOfaGUgbjqPOKTUv8TAqIzz7Z7Ynjo4shT5ECx2wbEcDNJva%2BImgrekJWTo9ZU2zJY1dywfsXvY3oDtf%2B9XuVUJUcRO22Ks%2FtxPqEsZvpUnigvHHy73tPkNNV5JGCoi9j1UZ0zkhts30ccZgWYAJ%2BlHfBxaafxYeoz9xVQvNkVbuadPYmnsefGNItpsQvRe4LFs8660fLIJss%2FYl7TWAogBgsWlplRjbDakxiOX1lOBcjGmT2%2B9w%2F%2FEYb3eyHhYudrmD133JIHPNoeXxhHEM0E02FfZexQQ2QDCV4%2FeQBjqZAQx5Y%2BYz8aiHw%2ByJ8KXs%2BISNkIsaVk1Q%2F7o5uJ2Bn1WYdKMNEeUm2YJUrXRKZ3wtXThgHCNmetCgou1ni9pWai3YqhZujUtTANQDO2qJuyp99sRZ%2BXTnJAED5lYNFq1o0F%2BEyflBsr8ufPb6XtKtrRCO5TELja3fl%2B6C4xbMjcJ6LxXwNn6V%2Fby9AXFcXPVKyZtR0zEz6TduvA%3D%3D'
      },
      {
        'software_type': software,
        'name': 'ReleaseNotes2.02.txt',
        'brand': 'Bobcat NA',
        'version': '2.02',
        'instructions': 'No change to file extension',
        'id': '01I4V2QH3JOSLO6JGD65B364AYPTDITOW5',
        'release_date': '2022-02-22T22:22:22Z',
        'locale': 'en-US',
        'type': 'releasenotes',
        'download_link': 'https://dibh-dev-dxp-software-files.s3.amazonaws.com/01I4V2QH3JOSLO6JGD65B364AYPTDITOW5?AWSAccessKeyId=ASIAWSTXIYHSNF7DII4E&Expires=1646133795&Signature=o3r4TUHxQwR1rKFzQUAtNPDMElA%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQCD9zsmtAbbAnyJnhlvmLjdUjdzNOIBQmrtCBkKTpVTYwIhAJTIqNkWP0f4JIcyLIhsDqXgVNUNeR5mxSvO8lGKJiFlKrMCCBMQABoMNDUyMjk1NDQyOTE2Igz4ud%2Bv4polYAAoc7YqkAKNmOyWImQApVZgA9lVwpiX%2FG6qJU1YV7dDsICnnGQ%2FbdAYUJJFrkt1gEhDCHxqRrNEFyYQOjD9%2FOfaGUgbjqPOKTUv8TAqIzz7Z7Ynjo4shT5ECx2wbEcDNJva%2BImgrekJWTo9ZU2zJY1dywfsXvY3oDtf%2B9XuVUJUcRO22Ks%2FtxPqEsZvpUnigvHHy73tPkNNV5JGCoi9j1UZ0zkhts30ccZgWYAJ%2BlHfBxaafxYeoz9xVQvNkVbuadPYmnsefGNItpsQvRe4LFs8660fLIJss%2FYl7TWAogBgsWlplRjbDakxiOX1lOBcjGmT2%2B9w%2F%2FEYb3eyHhYudrmD133JIHPNoeXxhHEM0E02FfZexQQ2QDCV4%2FeQBjqZAQx5Y%2BYz8aiHw%2ByJ8KXs%2BISNkIsaVk1Q%2F7o5uJ2Bn1WYdKMNEeUm2YJUrXRKZ3wtXThgHCNmetCgou1ni9pWai3YqhZujUtTANQDO2qJuyp99sRZ%2BXTnJAED5lYNFq1o0F%2BEyflBsr8ufPb6XtKtrRCO5TELja3fl%2B6C4xbMjcJ6LxXwNn6V%2Fby9AXFcXPVKyZtR0zEz6TduvA%3D%3D'
      },
      {
        'software_type': software,
        'name': software + ' 1.01 Installer.exe',
        'brand': 'Bobcat NA',
        'version': '1.01',
        'instructions': 'No change to file extension',
        'id': '01I4V2QH4UOXRZW4P66VEJBOXWW5VFYT2B',
        'release_date': '2011-11-11T11:11:11Z',
        'locale': 'en-US',
        'type': 'software',
        'download_link': 'https://dibh-dev-dxp-software-files.s3.amazonaws.com/01I4V2QH4UOXRZW4P66VEJBOXWW5VFYT2B?AWSAccessKeyId=ASIAWSTXIYHSNF7DII4E&Expires=1646133795&Signature=MNGo2xbYtHXsma8ODwbCKe4x9WE%3D&x-amz-security-token=IQoJb3JpZ2luX2VjEKv%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMSJIMEYCIQCD9zsmtAbbAnyJnhlvmLjdUjdzNOIBQmrtCBkKTpVTYwIhAJTIqNkWP0f4JIcyLIhsDqXgVNUNeR5mxSvO8lGKJiFlKrMCCBMQABoMNDUyMjk1NDQyOTE2Igz4ud%2Bv4polYAAoc7YqkAKNmOyWImQApVZgA9lVwpiX%2FG6qJU1YV7dDsICnnGQ%2FbdAYUJJFrkt1gEhDCHxqRrNEFyYQOjD9%2FOfaGUgbjqPOKTUv8TAqIzz7Z7Ynjo4shT5ECx2wbEcDNJva%2BImgrekJWTo9ZU2zJY1dywfsXvY3oDtf%2B9XuVUJUcRO22Ks%2FtxPqEsZvpUnigvHHy73tPkNNV5JGCoi9j1UZ0zkhts30ccZgWYAJ%2BlHfBxaafxYeoz9xVQvNkVbuadPYmnsefGNItpsQvRe4LFs8660fLIJss%2FYl7TWAogBgsWlplRjbDakxiOX1lOBcjGmT2%2B9w%2F%2FEYb3eyHhYudrmD133JIHPNoeXxhHEM0E02FfZexQQ2QDCV4%2FeQBjqZAQx5Y%2BYz8aiHw%2ByJ8KXs%2BISNkIsaVk1Q%2F7o5uJ2Bn1WYdKMNEeUm2YJUrXRKZ3wtXThgHCNmetCgou1ni9pWai3YqhZujUtTANQDO2qJuyp99sRZ%2BXTnJAED5lYNFq1o0F%2BEyflBsr8ufPb6XtKtrRCO5TELja3fl%2B6C4xbMjcJ6LxXwNn6V%2Fby9AXFcXPVKyZtR0zEz6TduvA%3D%3D'
      }
    ]
  }, {timing: 500})
}
