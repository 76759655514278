import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import store from '@/store'
import router from '@/router'
import {mockServer} from './server'
import '@/scss/custom.scss'
import App from '@/App.vue'
import oktaAuth from '@/okta'
import OktaVue from '@okta/okta-vue'
import '@okta/okta-auth-js/polyfill'
import moment from 'moment'

import commonMixin from '@/mixins/commonMixin'

import axios from '@/axios'
import {inMgnl, inLocalhostEditor} from '@/vue-editor/advanced-helper'

Vue.config.productionTip = false
Vue.prototype.moment = moment

if (inMgnl || inLocalhostEditor || process.env.VUE_APP_OFFLINE === 'true') {
  mockServer()
  const googleFonts = '<link rel="preconnect" href="https://fonts.googleapis.com">\n' +
    '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>\n' +
    '<link href="https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Condensed:wght@700&display=swap" rel="stylesheet">'
  document.head.innerHTML = document.head.innerHTML + googleFonts
} else {
  if (!inLocalhostEditor) Vue.use(OktaVue, {oktaAuth})
  import('./scss/font-face/_roboto-local.scss')
}
Vue.use(BootstrapVue)

async function getToken() {
  try {
    const token = await Vue.prototype.$auth.tokenManager.get('accessToken')
    return token.accessToken
  } catch (e) {
    return false
  }
}

axios.interceptors.request.use(
  async (config) => {
    const token = await getToken()
    if (token) {
      // Object.assign(config.headers, {Authorization: `Bearer ${token}`})
    }
    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) { //if page need authorization
    const token = await getToken() //wait for token
    if (!token) next() //allow to redirect to okta

    //check user access here
    next()
  } else next() //does not require authentication
})

window.vm = new Vue({
  mixins: [commonMixin(Vue)],
  router,
  store,
  render: h => h(App)
}).$mount('#app')
