import Vue from 'vue'
import Router from 'vue-router'
import SoftwarePage from '@/views/SoftwarePage'
import ImplicitCallback from '@/components/ImplicitCallback'
const _publicPath = process.env.VUE_APP_PUBLIC_PATH

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: _publicPath + '/error',
      name: 'Error',
      component: () => import('@/views/ErrorLandingPage')
    },
    {
      name: null,
      path: _publicPath + '/implicit/callback',
      component: ImplicitCallback
    },
    {
      path: '*',
      component: SoftwarePage,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

export default router
