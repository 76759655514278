<template>
  <div class="p-3 bg-gray-100 mb-3">
    <h2>{{ title || metadata['@name'] }}</h2>
    <b-row>
      <b-col cols="12" :md="$attrs.image ? '7' : '12'" v-html="$attrs.content" />
      <b-col v-if="$attrs.image" cols="12" md="5" class="text-center">
        <img :src="$attrs.image['@link']" class="img-fluid mt-4 mt-md-0 px-5" />
      </b-col>
    </b-row>
  </div>
</template>

<script>

export default {
  name: 'TextComponent',
  props: ['title', 'metadata']
}
</script>
