<script>
import {BOverlay} from 'bootstrap-vue'

export default {
  extends: BOverlay,
  props: {
    variant: {
      type: String,
      required: false,
      default: 'gray'
    },
    spinnerVariant: {
      type: String,
      required: false,
      default: 'primary'
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true
    },
    opacity: {
      type: String,
      required: false,
      default: '.7'
    },
    noFade: {
      type: Boolean,
      required: false,
      default: true
    }
  }
}
</script>
