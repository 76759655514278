<template>
  <div class="Basic">
    {{ $attrs }}
    <div class="hint">[Layout Page]</div>
    <h1>{{ title || metadata['@name'] }}</h1>
    <main>
      <div class="hint">[Main Area]</div>
      <span>Layout</span>
      <EditableArea v-if="main" :content="main" />
    </main>
    <div class="Extras">
      <div class="hint">[Extras Area]</div>
      <EditableArea v-if="extras" :content="extras" />
    </div>
  </div>
</template>

<script>
import EditableArea from '../vue-editor/EditableArea'

export default {
  name: 'Layout',
  components: {
    EditableArea
  },
  props: ['title', 'metadata', 'main', 'extras']
}
</script>
