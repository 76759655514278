<template>
  <b-navbar-nav
    class="profile align-items-center justify-content-center flex-column bg-md-dark px-2 cursor-pointer"
    :class="{'expanded': showCollapse}"
  >
    <div v-b-toggle.profile-flyout class="position-relative w-100 d-flex align-items-center justify-content-between">
      <div class="d-flex">
        <b-avatar
          :variant="showCollapse ? 'light' : 'dark'"
          size="3em"
          class="bg-transparent mr-md-2"
        >
          <b-icon icon="bobcaticon-user" class="profile-avatar-icon" fixed-width />
        </b-avatar>
        <div
          class="text-white d-none d-md-flex flex-column"
          :class="{'justify-content-center': !regionActive}"
        >
          <div class="text-nowrap">{{ user.name }}</div>
          <div v-if="regionActive" class="text-nowrap small">{{ regionActive }}</div>
        </div>
      </div>
      <a class="text-white d-none d-md-block dropdown-toggle pl-2 pr-3"
         href="#"
         role="button"
         data-toggle="dropdown"
         aria-haspopup="true"
         :aria-expanded="showCollapse ? 'true' : 'false'"
      />
    </div>

    <transition name="fade">
      <b-collapse v-show="showCollapse" id="profile-flyout" v-model="showCollapse" is-nav class="bg-dark py-3">
        <b-col cols="12" tag="ul" class="list-unstyled">
          <div class="d-md-none font-weight-normal text-white text-center mb-3">
            <p class="mb-0">{{ user.name }}</p>
            <p class="small mb-0">{{ currentRegionLabel }} {{ regionActive }}</p>
          </div>
          <slot @click="hide">
            <b-nav-item
              v-for="region in regionsList"
              :key="region.key"
              :disabled="region.disabled"
              class="bg-xs-black position-relative font-condensed"
              @click="changeRegion(region.key)"
            >
              <b-icon icon="bobcaticon-arrowrightleft" class="profile-icon" fixed-width />
              <span class="ml-2 text-white profile-label">{{ region.translation }}</span>
            </b-nav-item>
            <b-nav-item
              class="bg-xs-black position-relative font-condensed"
              @click="logout"
            >
              <b-icon icon="bobcaticon-logout" class="profile-icon profile-icon-large" fixed-width />
              <span class="ml-2 text-white profile-label">{{ logoutLabel }}</span>
            </b-nav-item>
          </slot>
        </b-col>
      </b-collapse>
    </transition>

    <transition name="fade">
      <div v-show="showCollapse"
           class="modal-backdrop"
           style="z-index: -1;"
           @click="showCollapse = false"
      />
    </transition>
  </b-navbar-nav>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      required: false
    },
    logoutLabel: {
      type: String,
      required: true
    },
    regions: {
      type: Array,
      default: () => []
    },
    currentRegionLabel: {
      type: String
    }
  },
  data() {
    return {
      showCollapse: false
    }
  },
  computed: {
    regionsList() {
      return this.regions.filter(region => region.active === false)
    },
    regionActive() {
      const active = this.regions.find(region => region.active === true)
      if (active) return active.translation
      return ''
    },
    avatarBadge() {
      if (this.regions.length === 0) return false
      return this.regions.length.toString()
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'main-menu-flyout' && isJustShown) this.showCollapse = false //close this menu when main menu is opened
    })
  },
  methods: {
    hide() {
      this.showCollapse = false
    },
    logout() {
      this.$emit('logout')
    },
    changeRegion(region) {
      this.$emit('change-region', region)
      this.showCollapse = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/grid';
@import '../scss/colors';
@import '../scss/transitions';

$profile-width: 22.375rem;

.profile {
  @include media-breakpoint-up('md') {
    width: $profile-width;
  }

  &.expanded {
    background: $white;
    @include media-breakpoint-up('md') {
      background: $gray-800;
    }
  }
  .dropdown-toggle {
    line-height: 0;
    &::after {
      border-top: 0.55em solid;
      border-right: 0.45em solid transparent;
      border-bottom: 0;
      border-left: 0.45em solid transparent;
      transition: $transition-base;
    }

    &[aria-expanded="true"] {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  &-icon {
    color: $white;
    font-size: 12px;
    width: 24px;
    height: 24px;

    &-large {
      font-size: 16px;
    }

    ::v-deep bicon {
      width: inherit;
    }
  }

  &-label {
    vertical-align: middle;
  }

  &-avatar-icon {
      font-size: 24px;
      @include media-breakpoint-up('md') {
        color: $white;
      }
  }
}

@include media-breakpoint-up('md') {
  .bg-md-dark {
    background-color: $dark;
  }
}

#profile-flyout {
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  display: table;
  top: 4rem;
  width: 100%;

  @include media-breakpoint-up('md') {
    left: auto;
    width: $profile-width;
  }

  .nav-item {
    &:hover {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 0.25rem;
        background-color: $primary;
        z-index: -1;
      }
    }
  }
}
</style>
