<template>
  <div class="p-3 bg-gray-100 mb-3">
    <b-row>
      <b-col cols="12" md="">
        <h2>{{ $attrs.titleField || metadata['@name'] }}</h2>
        <p>{{ $attrs.subtitleField }}</p>
      </b-col>
      <b-col cols="12" md="" class="text-md-right mb-3 mb-md-0">
        <template v-for="(link, i) in documentDownloads">
          <div :key="'documentDownload_' + i">
            <b-link v-if="documentLink(link.softwareDownloadDocument)" class="font-condensed" :href="documentLink(link.softwareDownloadDocument)" target="_blank"><b-icon :icon="link.icon" class="mr-1 text-large" />{{ link.label }}</b-link>
          </div>
        </template>
      </b-col>
    </b-row>

    <template v-for="(link, i) in softwareLinks">
      <div :key="'softwareLink_' + i" class="software-link cursor-pointer p-3 border1 bg-white mb-2" @click="downloadLink(link)">
        <div class="d-flex">
          <div v-if="softwareIcon(link.softwareIcon)" class="mr-2 d-flex align-items-center">
            <img :src="softwareIcon(link.softwareIcon)" width="34" height="34">
          </div>
          <div class="software-link-title font-condensed">{{ link.softwareLabel }}</div>
        </div>
        <div v-html="link.content"></div>
      </div>
    </template>
    <BError v-if="error">{{ 'downloadError' | translate(labels, 'shared_CommonErrors') }}</BError>
  </div>
</template>

<script>
import getCloudinaryMeta from '@/helpers/cloudinaryUtils'
import {contentService} from '../../services'
import BError from '@/components/ui/BError'
import {mapGetters} from 'vuex'

export default {
  name: 'DownloadComponent',
  components: {BError},
  props: ['title', 'metadata'],
  data() {
    return {
      error: false
    }
  },
  computed: {
    ...mapGetters({
      getLanguage: 'software/getLanguage'
    }),
    documentDownloads() { return this.$attrs?.documentDownloads?.['@nodes'].map(name => this.$attrs.documentDownloads[name]) || [] },
    softwareLinks() { return this.$attrs?.softwareLinks?.['@nodes'].map(name => this.$attrs.softwareLinks[name]) || [] }
  },
  methods: {
    softwareIcon(content) {
      const link = /(https?:\/\/[^" ]*)/i
      const imageSrc = content?.valueRichText?.match(link)
      if (Array.isArray(imageSrc)) {
        return imageSrc[0]
      } else
        return false
    },
    documentLink(cloudinaryLink) {
      const meta = getCloudinaryMeta(cloudinaryLink)
      return meta.secure_url || false
    },
    async downloadLink(link) {
      this.error = false
      contentService.fetchDownloadLink(link['@id'], 'softwareDownloadFile', this.getLanguage).then(r => {
        let link = document.createElement('a')
        link.href = r.data.link
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        link.remove()
      }).catch(() => {
        this.error = true
      })
    }
  }
}
</script>
