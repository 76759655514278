import _merge from 'lodash.merge'

export default {
  restoreState(state) {
    let stateRestored = {}

    try {
      stateRestored = localStorage.getItem('mymachine_state') ? JSON.parse(localStorage.getItem('mymachine_state')) : {}
    } catch (e) {
      /* eslint-disable no-console */
      console.error('Error parsing JSON string', e)
      /* eslint-enable no-console */
    }

    _merge(state, stateRestored) //deep merge objects
    state.stateRestored = true
  },
  saveFeatures(state, payload) {
    state.features = payload
  },
  saveUser(state, u) {
    state.user = Object.assign({}, u) //trigger reactivity
  },
  saveIdentity(state, i) {
    state.identity = i
  },
  saveRegionConcept(state, payload) {
    state.regionConcepts = {...state.regionConcepts, [payload.region]: payload.data}
  },
  saveRegion(state, payload) {
    const _key = this.getters['software/getCurrentUserKey']

    state.region[_key] = payload
    state.region = Object.assign({}, state.region) //trigger reactivity
  },
  saveLanguage(state, l) {
    const _key = this.getters['software/getCurrentUserKey']
    const _region = this.getters['software/getRegion']
    if (!_key || !_region) return
    state.language[_key] = state.language[_key] || {}
    state.language[_key][_region] = l
    state.language = Object.assign({}, state.language) //trigger reactivity
  },
  saveShowConcepts(state, payload) {
    state.showConceptNames = payload
  }
}
