export default {
  getCurrentUser: state => {
    return state ? state.user : {}
  },
  getAppReady: state => {
    return state && state.stateRestored && state.user && state.user.email ? true : false
  },
  getCurrentUserKey: (state, getters) => {
    // for now email
    return state && getters.getCurrentUser ? getters.getCurrentUser.email : null
  },
  getIdentity: state => {
    return state ? state.identity : null
  },
  getRegionalizedFeatures: (state, getters) => {
    const currentRegion = getters.getRegion
    return state ? Object.fromEntries(Object.entries(state.features).filter(f => f[0].endsWith(currentRegion)).map(f => [f[0].replace(currentRegion, ''), f[1]])) : {}
  },
  getFeatures: state => {
    return state ? state.features : {}
  },
  getRegion: (state, getters) => {
    const _key = getters.getCurrentUserKey
    return _key && state.region[_key] ? state.region[_key] : null
  },
  getLanguage: (state, getters) => {
    const _key = getters.getCurrentUserKey
    const _region = _key && state.region[_key] ? state.region[_key] : null
    return _key && _region && state.language[_key] && state.language[_key][_region] ? state.language[_key][_region] : null
  },
  getConcepts: (state, getters) => {
    const _region = getters.getRegion
    const _language = getters.getLanguage
    if (state.showConceptNames) return {'show': 'concepts'}
    return _region && _language && state.regionConcepts?.[_region]?.[_language] || state.regionConcepts?.NA?.en || {} //get NA en when no region selected
  },
  getAvailableLanguages: (state, getters) => {
    const _region = getters.getRegion
    return _region && state.regionConcepts?.[_region] ? Object.keys(state.regionConcepts[_region]) : ['en']
  },
  isSetRegion: (state, getters) => {
    return !!getters.getRegion
  }
}
