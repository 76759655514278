import {createServer} from 'miragejs'
import contentApi from './content.api'
import dpApi from './dp.api'
import magnoliaApi from './magnolia.api'
import dpmmApi from './dpmm.api'
import api from '@/api'

export function mockServer({environment = 'development'} = {}) {
  let server = createServer({
    environment,

    routes() {
      contentApi(this)
      dpApi(this)
      dpmmApi(this)

      if (process.env.VUE_APP_OFFLINE !== 'true') {
        this.passthrough('https:' + api.urls.MAGNOLIA_AUTHOR + '**')
        this.passthrough('https:' + api.urls.MAGNOLIA_PUBLIC + '**')
        this.passthrough('https:' + api.urls.API + '/content/**')
        this.passthrough('http://' + window.location.host + '/**')
      } else {
        magnoliaApi(this)
      }
    }
  })
  return server
}
