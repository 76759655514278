export default function (src) {
  if (!src) { return undefined }
  const file = src['@id'] || src // nested nodes sometimes has object instead of string
  const fileData = file.split('cloudinary:')[1].split('#')
  let metadata = {}
  try {
    metadata = JSON.parse(atob(fileData[1]))
  } catch {
    // eslint-disable-next-line
    console.error(`Unable to get metadata object for asset: ${file}`)
  }
  return metadata
}
