<template>
  <div class="bg-gray-100 rounded font-condensed position-relative overflow-hidden d-lg-none">
    <overlay-wrapper :show="loading">
      <div v-show="isSomethingBefore"
           class="inpage-nav-arrows inpage-before box-shadow rounded-left"
           @click="scrollBack"
      >
        <div class="icon-wrapper d-flex align-items-center">
          <span class="icon" />
        </div>
      </div>

      <b-nav ref="navigationList" class="inpage-nav inpage-nav-horizontal d-md-flex flex-nowrap text-center" data-test-id="inpage-nav-mobile">
        <li v-for="(itm, i) in items"
            :key="'page_navigation_item_' + i"
            class="nav-item"
            :class="{'disabled':itm.disabled}"
        >
          <router-link
            class="nav-link d-inline-flex flex-column"
            :to="{ path: itm.path, query: { serial: $store.getters['mymachine/getCurrentSerial'] } }"
            :exact="itm.path === '/'"
            exact-path
            :data-test-id="'nav-page-vertical-item_' + i"
            :event="!itm.disabled ? 'click' : ''"
          >
            <b-icon v-if="itm.icon && itm.icon !== 'null'" :icon="itm.icon" class="text-large half-height d-flex align-items-end" fixed-width />
            <div class="d-flex align-items-center justify-content-center h-100">{{ itm.title }}</div>
          </router-link>
        </li>
      </b-nav>

      <div v-show="isSomethingAfter"
           class="inpage-nav-arrows inpage-next box-shadow rounded-right"
           @click="scrollForth"
      >
        <div class="icon-wrapper d-flex align-items-center">
          <span class="icon" />
        </div>
      </div>

      <div v-if="disabled" class="mask disabled" />
    </overlay-wrapper>
  </div>
</template>

<script>
import InPageNavigationDesktop from './InPageNavigationDesktop'
import debounce from 'lodash.debounce'

export default {
  extends: InPageNavigationDesktop,
  data() {
    return {
      scrollPosition: 0,
      screenWidth: 0,
      navigationWidth: 0
    }
  },
  computed: {
    isSomethingBefore() {
      return this.scrollPosition > 0
    },
    isSomethingAfter() {
      if (this.scrollPosition == 0 && this.navigationWidth == this.screenWidth) return 0
      return Math.floor(this.navigationWidth - this.screenWidth - this.scrollPosition) > 1
    }
  },
  watch: {
    loading(n) {
      if (!n && this.$refs.navigationList) this.$nextTick(() => this.scrollToActive())
    }
  },
  mounted() {
    if (this.$refs.navigationList) {
      this.handleScroll()
      this.$refs.navigationList.addEventListener('scroll', this.handleScroll)
    }
    this.handleResize = debounce(this.handleScroll, 100)
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    if (this.$refs.navigationList) this.$refs.navigationList.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleScroll() {
      this.navigationWidth = this.$refs?.navigationList.scrollWidth || 0
      this.screenWidth = this.$refs?.navigationList.offsetWidth || 0
      this.scrollPosition = this.$refs?.navigationList.scrollLeft || 0
    },
    scrollBack() {
      this.$refs.navigationList.scrollLeft -= this.screenWidth / 4
    },
    scrollForth() {
      this.$refs.navigationList.scrollLeft += this.screenWidth / 4
    },
    scrollToActive() {
      if (this.items.length) {
        const itemWidth = this.$refs.navigationList.querySelector('li').offsetWidth
        const maxItemsVisible = Math.floor(this.screenWidth / itemWidth)
        const currentActive = this.items.map(x => x.active).indexOf(true) + 1
        let scrollOffset = currentActive - maxItemsVisible
        this.$nextTick(() => {
          this.$refs.navigationList.scrollLeft += scrollOffset * itemWidth
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../scss/colors';
@import '../../scss/transitions';

.inpage-nav {
  &-horizontal {
    overflow-x: auto;
    scroll-behavior: smooth;

    .nav-item {
      min-width: 6.5rem;
      min-height: 5rem;
      width: 25%;

      .nav-link {
        font-size: 0.875rem;
        height: 100%;
        width: 100%;
        padding: 0;
        line-height: 1rem;
        display: flex;
        transition: $transition-base;

        .half-height {
          flex: 0 0 50%;
          justify-content: center;
        }
      }
    }
  }

  &-arrows {
    cursor: pointer;
    background: $white;
    border-radius: .5rem;
    height: 100%;
    position: absolute;
    text-align: center;
    top: 0;
    width: 1.125rem;

    .icon-wrapper {
      height: 100%;
      padding: .25rem;
    }

    .icon {
      border: solid $orange;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
    }

    &.inpage {
      &-next,
      &-before {
        z-index: 2;
      }

      &-before {
        left: 0;

        .icon {
          transform: rotate(135deg);
        }
      }

      &-next {
        right: 0;

        .icon {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
</style>
