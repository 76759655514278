<template>
  <b-container class="h-100">
    <div v-if="error" key="error" class="p-5">
      <BError>{{ error }}</BError>
    </div>

    <div v-else key="loading" class="bc-spinner" />
  </b-container>
</template>

<script>
import BError from '@/components/ui/BError'
export default {
  name: 'ImplicitCallback',
  components: {BError},
  data() {
    return {
      error: null
    }
  },
  async beforeMount() {
    this.error = this.$route.query.error_description || null
    if (this.$route.query.code && this.$route.query.code.length > 0) { //got code from okta
      try {
        await this.$auth.handleLoginRedirect()
        this.$emit('authenticated') //trigger App to get user
      } catch (e) {
        this.error = e.message
      }
    } else {
      if(!this.error) {
        this.$router.push('/') //something went wrong, probably user entered url manually, redirect to root
      } else {
        this.$router.push({name: 'Error', query: {'error': 'oktaUnauthorized', 'message': this.error}})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 4rem;
}


.bc-spinner {
  position: relative;
  top: 50%;
  left:50%;
  transform: translateY(-50%);
  width: 4.375rem;
  height: 4.375rem;

  &:after {
    content: " ";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid;
    border-color: black transparent black transparent;
    animation: rings 1.5s ease-in-out infinite;
  }
}

@keyframes rings {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
