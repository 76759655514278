<template>
  <div id="app">
    <BHeader ref="header"
             service="Owner Portal"
             :logout-label="translatedLabel('logoutLabel', 'shared_Header')"
             :current-region-label="translatedLabel('currentRegion', 'shared_Regions')"
             :menu-items="menuItems"
             :user="getCurrentUser"
             :regions="regions"
             @logout="logout"
             @change-region="saveRegion"
    >
      <template v-if="inEditor" v-slot:profile-menu>
        <div
          class="bg-xs-black position-relative font-condensed"
          @click="openProfileModal"
        >
          <b-icon icon="cog" class="text-white profile-icon" fixed-width />
          <span class="ml-2 text-white profile-label">Change impersonated user access level</span>
        </div>
      </template>
    </BHeader>
    <router-view @authenticated="getUser" />
    <div v-if="loading" class="bc-spinner" />
    <region-selector-modal v-if="showRegionModal" :regions="regions" @select="saveRegion" />
    <profile-modal ref="profileModal" />
  </div>
</template>

<script>
import BHeader from '@/components/BHeader'
import RegionSelectorModal from '@/components/ui/RegionSelectorModal'
import ProfileModal from '@/components/ui/ProfileModal'
import appConfig from '@/config'
import {mapGetters, mapMutations} from 'vuex'
import {inMgnl, inLocalhostEditor} from '@/vue-editor/advanced-helper'

const initMenuElements = ['software']

export default {
  conceptName: ['shared_HeaderMenu', 'shared_RegionNames'],
  components: {
    BHeader,
    RegionSelectorModal,
    ProfileModal
  },
  data() {
    return {
      loading: false,
      error: null,
      user: {},
      inEditor: inMgnl || inLocalhostEditor,
      menuElements: initMenuElements,
      availableRegions: [],
      showRegionModal: false
    }
  },
  computed: {
    ...mapGetters({
      getCurrentUser: 'software/getCurrentUser',
      getAppReady: 'software/getAppReady',
      isSetRegion: 'software/isSetRegion',
      getRegion: 'software/getRegion',
      getRegionalizedFeatures: 'software/getRegionalizedFeatures',
      getLanguage: 'software/getLanguage',
      getIdentity: 'software/getIdentity',
      languageList: 'software/getAvailableLanguages'
    }),
    regions() {
      const regions = this.availableRegions
      return regions.map(conceptKey => ({
        key: conceptKey,
        translation: this.translatedLabel(conceptKey, 'shared_RegionNames'),
        active: this.getRegion === conceptKey,
        disabled: inMgnl
      }))
    },
    menuItems() {
      let items = []
      for(const i of this.menuElements) {
        let iconConcept = i + 'Icon'
        let titleConcept = i + 'Title'
        let pathConcept = i + 'Path'
        items.push(
          {
            'icon': this.$options.filters.translate(iconConcept, this.labels, 'shared_HeaderMenu'),
            'title': this.$options.filters.translate(titleConcept, this.labels, 'shared_HeaderMenu'),
            'path': this.$options.filters.translate(pathConcept, this.labels, 'shared_HeaderMenu'),
            'disabled': inMgnl
          }
        )
      }
      return items
    }
  },
  watch: {
    $route(to) {
      this.loading = to.name === null // show/hide loader if route name is null
    },
    async getAppReady() {
      this.getRegions()
    },
    getRegion(newRegion, oldRegion) {
      if (newRegion && !this.getLanguage && this.getIdentity) this.detectLanguage()
      this.$store.dispatch('software/fetchRegionConcepts', newRegion)

      document.body.classList.remove(oldRegion)
      document.body.classList.add(newRegion)
    },
    getIdentity(identity) {
      if (identity) this.detectLanguage()
    }
  },
  async created() {
    this.loading = this.$route.name === null // show/hide loader if route name is null
    this.getUser() // try to get user
    this.getRegions()

    switch (appConfig.nodeEnv) { //add prefix on dev environments
    case 'development': //serve
      if (process.env.VUE_APP_PREFIX)
        document.title = process.env.VUE_APP_PREFIX + ' Software'
      else
        document.title = (process.env.VUE_APP_OFFLINE === 'true' ? '[PREV] ' : '[DEV] ') + 'Software'
      break
    case 'int': //build int
      document.title = '[INT] Software'
      break
    case 'qa': //build qa
      document.title = '[QA] Software'
      break
    default: //serve mode
      document.title = (process.env.VUE_APP_PREFIX ? process.env.VUE_APP_PREFIX : '') + 'Software'
    }
  },
  methods: {
    ...mapMutations({
      saveRegion: 'software/saveRegion',
      saveFeatures: 'software/saveFeatures',
      saveLanguage: 'software/saveLanguage'
    }),
    detectLanguage() {
      if (!this.getLanguage && this.getIdentity && this.languageList && this.getRegion) {
        let lang = 'en' //default fallback language
        const navigatorLanguages = (navigator.languages && navigator.languages.length) ? navigator.languages : [navigator.userLanguage] || [navigator.language] || [navigator.browserLanguage] || ['en']
        for (let navigatorLanguage of navigatorLanguages) {
          navigatorLanguage = navigatorLanguage.substr(0, 2)
          if (navigatorLanguage && this.languageList.indexOf(navigatorLanguage) !== -1) {
            lang = navigatorLanguage
            break
          }
        }
        const identityLanguage = this.getIdentity.language?.toLowerCase()
        if (identityLanguage && this.languageList.indexOf(identityLanguage) !== -1) lang = identityLanguage
        this.saveLanguage(lang)
      }
    },
    async getUser() {
      if (this.$auth) {
        try {
          this.user = await this.$auth.getUser()
          this.$store.commit('software/saveUser', this.user)
        } catch (e) {
          await this.$auth.signInWithRedirect()
          this.error = e
        }
      } else {
        this.user = {
          directory_group: ['directory-internal', 'directory-dealer'],
          company_name: 'Internal',
          email: 'john.doe@doosan.com',
          email_verified: true,
          family_name: 'John',
          given_name: 'Doe',
          locale: 'en-US',
          name: 'Impersonated - all access',
          preferred_username: 'johndoe@corp.doosan.com',
          sub: '00uph2ttkqHDNimYs0h7',
          updated_at: 1611063304,
          zoneinfo: 'America/Los_Angeles'
        }
        this.$store.commit('software/saveUser', this.user)
      }
      this.$store.dispatch('software/fetchRegionConcepts', 'NA')
    },
    getRegions() {
      if (inMgnl) {
        const matcher = /\/owner-portal-(?<region>[a-z]{2,6})\/.*(?<language>[a-z]{2})\/(?<path>.+)/
        const found = this.$route.path.match(matcher)
        if (found) {
          this.saveRegion(found.groups.region.toUpperCase())
          this.saveLanguage(found.groups.language)
        } else {
          this.saveRegion('NA')
          this.saveLanguage('en')
        }
        return
      }
      this.availableRegions = ['NA']
      this.saveRegion(this.availableRegions[0])
    },
    async logout() {
      if (this.$auth) await this.$auth.signOut()
    },
    openProfileModal() {
      this.$refs.header.hideProfile()
      this.$refs.profileModal.show()
    }
  }
}
</script>

<style lang="scss">
@import './scss/grid';

html {
  height: 100%;
}

#main {
  padding-top: 4rem;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.mgnlEditor, .mgnlEditor div {
  z-index: 1000!important;
}

/* pulse insight show only in NA region */
#pulse_feedback_tab {
  display: none;
}

body.NA #pulse_feedback_tab {
  display: block;
}

#onetrust-consent-sdk {
  display: none;
}
.EMEA #onetrust-consent-sdk{
  display: block;
}
</style>
