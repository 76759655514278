<template>
  <b-nav type="dark" class="font-condensed flex-grow-1 d-none d-md-flex px-3">
    <b-nav-item v-for="(item, i) in menuitems"
                :key="'main-menu-desktop-item_' + i"
                link-classes="position-relative p-0 d-flex align-items-center"
                class="d-flex px-3"
                :data-test-id="'main-menu-desktop-item_' + i"
                :href="item.path"
                :active="isCurrentPath(item.path)"
                :disabled="item.disabled"
    >
      <b-icon v-if="item.icon && item.icon !== 'null'" :icon="item.icon" class="mr-1" />
      <span class="d-none d-lg-inline-block ml-1 text-uppercase">{{ item.title }}</span>

      <nav v-if="item.items"
           class="subnavigation d-none bg-white d-md-flex row box-shadow rounded p-0 py-2 font-condensed"
           :data-test-id="'main-menu-desktop-subnav_' + i"
      >
        <ul class="list-unstyled mb-0 w-100">
          <b-dropdown-item v-for="(s, j) in item.items"
                           :key="'main-menu-desktop-subnav_' + i + '_item_' + j"
                           event="mousedown"
                           :data-test-id="'main-menu-desktop-subnav_' + i + '_item_' + j"
                           :active="isCurrentPath(s.path, true)"
                           :href="s.path"
                           :disabled="s.disabled"
          >
            <b-icon v-if="s.icon && s.icon !== 'null'" :icon="s.icon" class="mr-2" fixed-width />
            <b-icon v-else-if="hasIcons(item)" :icon="'cog'" class="invisible mr-2" fixed-width />
            {{ s.title }}
          </b-dropdown-item>
        </ul>
      </nav>
    </b-nav-item>
  </b-nav>
</template>

<script>
export default {
  props: {
    menuitems: {
      type: Array,
      required: true
    }
  },
  methods: {
    hasIcons(item) {
      return item.items.filter(x => x.icon && x.icon !== 'null').length
    },
    isCurrentPath(path, exact) {
      if (!path) return false
      const currentPath = window.location.pathname.split('/').filter(Boolean)
      path = path.split('/').filter(Boolean)
      const match = path.every((e, i) => e === currentPath[i])
      return exact ? (currentPath.length === path.length && match) : match
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/colors';
@import '../scss/grid';
@import '../scss/z-index';
@import '../scss/transitions';

.nav {
  .nav-item {

    .bicon {
      font-size: 1.1rem;
    }

    & > a:not(.disabled) {
      transition: $transition-base;
      opacity: .7;

      & > span {
        color: $white;
      }

      &.active:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: .25rem;
        background-color: $primary;
        z-index: -1;
      }

      &:active,
      &.active,
      &:focus {
        opacity: 1;
      }

      &:hover {
        box-shadow: 0 .5rem 1rem rgba($black, .15);
      }
    }

    & > a.disabled {
      color: $gray-800;
    }

    &:active,
    &.active,
    &:focus,
    &.hover,
    &:hover {
      & > a {
        opacity: 1;
      }
    }

    .subnavigation {
      color: inherit;
      position: absolute;
      top: -1000px;
      transition: $transition-fade;
      opacity: 0;
      overflow: hidden;
      min-width: 15rem;
      max-width: 40rem;
      cursor: default;

      ::v-deep a{
        transition: $transition-base;

        &:active,
        &.active {
          color: $primary;
          background-color: transparent;
        }

        &.hover,
        &:hover,
        &:focus {
          background-color: $gray-400;
        }
      }
    }

    &:hover {

      .subnavigation {
        opacity: 1;
        top: 3.2rem;
        z-index: $zindex-modal;
        overflow: visible;
      }
    }
  }
}
</style>
