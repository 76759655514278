import api from '@/api'
import dealerportal from './responses/content-api/dealerportal'

export default function (server) {
  // translations: get all
  server.get(location.protocol + api.getEndpoint(api.endpoints.CONTENT_API.REGION_TRANSLATIONS, {region: ':region'}), (schema, request) => {
    let {region} = request.params
    return dealerportal[region]
  })
}
