export default {
  '@name': 'test-ks',
  '@path': '/dp-software/na/test-ks',
  '@id': 'e792dcea-546a-492d-8115-a7c6e7a6f700',
  '@nodeType': 'mgnl:page',
  'heading': 'Heading text',
  'mgnl:template': 'dp-software:pages/index',
  'contractTypes': [],
  'description': 'Description',
  'contractCodes': [],
  'title': 'New Download component',
  'templateScript': 'https://develop.bobcatdealernet.dev.dice-tools.com/service/software/',
  'keywords': 'Keyword',
  'main': {
    '@name': 'main',
    '@path': '/dp-software/na/test-ks/main',
    '@id': '11a45f10-9de9-43f2-8db3-17dbccf07cd8',
    '@nodeType': 'mgnl:area',
    '0': {
      '@name': '0',
      '@path': '/dp-software/na/test-ks/main/0',
      '@id': '1d591ad3-4574-4cc5-bf1c-dd9e87fe2071',
      '@nodeType': 'mgnl:component',
      'subtitleField': 'Super Subtitle',
      'titleField': 'Softwares Automated Component',
      'mgnl:template': 'dp-software:components/sectionSoftwaresAutomated',
      'softwareItems': {
        '@name': 'softwareItems',
        '@path': '/dp-software/na/test-ks/main/0/softwareItems',
        '@id': 'db278bdd-4ad7-42ef-8ad4-f601966674b1',
        '@nodeType': 'mgnl:contentNode',
        'softwareItems0': {
          '@name': 'softwareItems0',
          '@path': '/dp-software/na/test-ks/main/0/softwareItems/softwareItems0',
          '@id': '992b1c47-0719-4133-bd4c-4a6b5c90c61e',
          '@nodeType': 'mgnl:contentNode',
          'comboSelect': '85fe57f1-27ff-4ae0-b4bf-e2f926230504',
          'softwareLabel': 'Software label 1',
          'releaseNoteLabel': 'Release notes label 1',
          'softwareCategory': {
            '@name': 'service-analyzer',
            '@path': '/dealer-portal/software-types/service-analyzer',
            '@id': '676b9ef3-7b92-43d4-b0a5-bdcac1e52258',
            '@nodeType': 'mgnl:category',
            'displayName': 'ServiceAnalyzer',
            'level': 'level-1',
            'relatedUUID': {
              '@name': 'relatedUUID',
              '@path': '/dealer-portal/software-types/service-analyzer/relatedUUID',
              '@id': '52aee695-3b95-4c98-a0ff-df4a2600dedb',
              '@nodeType': 'mgnl:contentNode',
              '@nodes': []
            },
            '@nodes': ['relatedUUID']
          },
          '@nodes': []
        },
        'softwareItems1': {
          '@name': 'softwareItems1',
          '@path': '/dp-software/na/test-ks/main/0/softwareItems/softwareItems1',
          '@id': '3d8f7c3a-315f-459d-b466-29e95e30469a',
          '@nodeType': 'mgnl:contentNode',
          'comboSelect': '1bf78dc0-6d17-454e-880b-39b6f3ba7c2b',
          'softwareLabel': '[software] released [release] in version [version]',
          'releaseNoteLabel': 'Release notes for [software] released in [release] version [version]',
          'softwareCategory': {
            '@name': 'diagmaster',
            '@path': '/dealer-portal/software-types/diagmaster',
            '@id': 'c2d7737c-dfb0-491a-bb96-7bab7e53d27f',
            '@nodeType': 'mgnl:category',
            'displayName': 'VersaAnalyzer',
            'level': 'level-1',
            'relatedUUID': {
              '@name': 'relatedUUID',
              '@path': '/dealer-portal/software-types/diagmaster/relatedUUID',
              '@id': '54587a39-02ad-484c-8b45-b20e80412626',
              '@nodeType': 'mgnl:contentNode',
              '@nodes': []
            },
            '@nodes': ['relatedUUID']
          },
          '@nodes': []
        },
        '@nodes': ['softwareItems0', 'softwareItems1']
      },
      'documentDownloads': {
        '@name': 'documentDownloads',
        '@path': '/dp-software/na/test-ks/main/0/documentDownloads',
        '@id': 'fcb98f5a-91b8-4473-90d9-8c64d3e0324f',
        '@nodeType': 'mgnl:contentNode',
        'documentDownloads0': {
          '@name': 'documentDownloads0',
          '@path': '/dp-software/na/test-ks/main/0/documentDownloads/documentDownloads0',
          '@id': 'd75559ba-31b9-473e-83d9-5460bf71f0f5',
          '@nodeType': 'mgnl:contentNode',
          'label': 'Documents download label 1',
          'softwareDownloadDocument': 'cloudinary:bobcat-mb-assets/nao-hr/bobcat-brand/bobcat#eyJhc3BlY3RfcmF0aW8iOjIuNzAyNywiZm9ybWF0IjoianBnIiwicmVzb3VyY2VfdHlwZSI6ImltYWdlIiwic2VjdXJlX3VybCI6Imh0dHBzOi8vcmVzLmNsb3VkaW5hcnkuY29tL2Rvb3Nhbi1ib2JjYXQtZGV2L2ltYWdlL3VwbG9hZC92MTY0NTYxMzE3NC9ib2JjYXQtbWItYXNzZXRzL25hby1oci9ib2JjYXQtYnJhbmQvYm9iY2F0LmpwZyIsImNyZWF0ZWRfYXQiOiIyMDIyLTAyLTIzVDEwOjQ2OjE0KzAwOjAwIiwiYmFja3VwX2J5dGVzIjo0NDY1MTUsImFzc2V0X2lkIjoiOWQzZGNlMTZmYzBkYmZhNTY5NGU5ZThmYzVhYjRkYjIiLCJ0eXBlIjoidXBsb2FkIiwiZGlzcGxheV9uYW1lIjoiYm9iY2F0IiwidmVyc2lvbiI6MTY0NTYxMzE3NCwiYWNjZXNzX21vZGUiOiJwdWJsaWMiLCJjcmVhdGVkX2J5Ijp7ImFjY2Vzc19rZXkiOiI4NDM0MzIyMzExNDE4MTIifSwidXJsIjoiaHR0cDovL3Jlcy5jbG91ZGluYXJ5LmNvbS9kb29zYW4tYm9iY2F0LWRldi9pbWFnZS91cGxvYWQvdjE2NDU2MTMxNzQvYm9iY2F0LW1iLWFzc2V0cy9uYW8taHIvYm9iY2F0LWJyYW5kL2JvYmNhdC5qcGciLCJwdWJsaWNfaWQiOiJib2JjYXQtbWItYXNzZXRzL25hby1oci9ib2JjYXQtYnJhbmQvYm9iY2F0IiwidXBsb2FkZWRfYnkiOnsiYWNjZXNzX2tleSI6Ijg0MzQzMjIzMTE0MTgxMiJ9LCJmaWxlbmFtZSI6ImJvYmNhdCIsInBpeGVscyI6MzcwMDAwLCJ1cGxvYWRlZF9hdCI6IjIwMjItMDItMjNUMTA6NDY6MTQrMDA6MDAiLCJieXRlcyI6NDQ2NTE1LCJjb250ZXh0Ijp7fSwid2lkdGgiOjEwMDAsImV0YWciOiI4ODkyNjU1NWIzZTQ3N2ZjNjMxNDY5M2Y1M2YxMmMyYyIsImhlaWdodCI6MzcwLCJzdGF0dXMiOiJhY3RpdmUifQ==',
          'icon': 'book',
          '@nodes': []
        },
        '@nodes': ['documentDownloads0']
      },
      '@nodes': ['softwareItems', 'documentDownloads']
    },
    '@nodes': ['0']
  },
  '@nodes': ['main']
}
