import {contentService} from '@/services'

export default {
  fetchRegionConcepts(context, region) {
    let _l = {}
    if (!context.state.regionConcepts[region]) //fetch when doesn't exist
      contentService.fetchRegionTranslations(region.toLowerCase()).then(r => {
        _l = r.data
      }).catch(async () => {
        await contentService.fetchBackupTranslations(region.toLowerCase()).then(r => {
          _l = r.data
        }).catch(() => {
          _l = {error: 'Error while fetching translations'}
        })
      }).finally(() => {
        context.commit('saveRegionConcept', {region, data: _l})
      })
  }
}
