<template>
  <aside id="sidebar">
    <div class="p-4 d-none d-lg-block">
      <h1 class="font-condensed">
        {{ 'headingLabel' | translate(labels, 'software_SidebarComponent') }}
      </h1>
    </div>

    <InPageNavigationMobile :loading="loading"
                            :items="items"
    />

    <InPageNavigationDesktop :loading="loading"
                             :items="items"
    />
  </aside>
</template>

<script>
import InPageNavigationDesktop from '@/components/ui/InPageNavigationDesktop'
import InPageNavigationMobile from '@/components/ui/InPageNavigationMobile'

export default {
  components: {
    InPageNavigationDesktop,
    InPageNavigationMobile
  },
  props: {
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    sections: {
      type: Array,
      required: true,
      default: () => {return []}
    },
    sectionTitle: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    items() {
      let items = []
      for(const item of this.sections) {
        items.push({path: item.key, title: item.title, icon: item.icon, disabled: item.disabled})
      }
      return items
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../scss/colors';
  @import '../../scss/grid';

  #sidebar {
    background: $white;
  }

  @include media-breakpoint-up('lg') {
    #sidebar {
      min-height: 100%;
      width: 21.5rem;
      background: $gray-100;
      align-items: center;
      justify-content: space-between;

      & > div {
        box-shadow: none;
      }
    }
  }
</style>
