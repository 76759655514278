<template>
  <section>
    <h1>{{ content.title }}</h1>
    <p>{{ content.heading }}</p>
    <EditableArea
      v-for="(area, i) in areas"
      :key="'editable-area__' + i"
      :content="area"
      :parent-template-id="pageTemplateId"
    />
  </section>
</template>

<script>
import {TemplateAnnotations, PageEditorBridge} from '@magnolia/template-annotations'
import EditableMixin from './EditableMixin'
import EditableArea from './EditableArea'
import {insertComments, removeComments} from './helper'
import {inMgnlIframe, inLocalhostEditor} from './advanced-helper'

export default {
  name: 'EditablePage',
  components: {
    EditableArea
  },
  mixins: [EditableMixin],
  props: {
    content: {type: Object},
    config: {type: Object},
    templateAnnotations: {type: Object},
    templateDefinitions: {type: Object}
  },
  data() {
    return {
      pageTemplateId: this.content['mgnl:template'],
      reactiveTemplateAnnotations: {}
    }
  },
  computed: {
    renderingContent() {
      return TemplateAnnotations.generateMissingAreas(this.content, this.reactiveTemplateAnnotations.value)
    },
    areas() {
      return this.content['@nodes'] ? this.content['@nodes'].map(nodeName => this.content[nodeName]).filter(n => n['@nodeType'] === 'mgnl:area') : []
    }
  },
  mounted() {
    this.reactiveTemplateAnnotations.value = this.templateAnnotations
    PageEditorBridge.init()
    this.writeAnnotation()
  },
  beforeUpdate() {
    this.updateTemplateAnnotationsProvider()
    this.writeAnnotation()
  },
  methods: {
    writeAnnotation() {
      if (!this.content) {
        return
      }
      if (this.reactiveTemplateAnnotations.value && this.reactiveTemplateAnnotations.value[this.renderingContent['@path']]) {
        removeComments(this.$el)
        const commentOpen = this.reactiveTemplateAnnotations.value[this.renderingContent['@path']]
        insertComments(this.$el, commentOpen, '/cms:page')
      } else { // FIXED WAY
        removeComments(this.$el)
        const templateDefinition = this.templateDefinitions[this.pageTemplateId]
        if ((inMgnlIframe || inLocalhostEditor) && templateDefinition) {
          const commentOpen = TemplateAnnotations.getPageCommentString(
            this.content,
            templateDefinition
          )
          insertComments(this.$el, commentOpen, '/cms:page')
        }
      }
    },
    updateTemplateAnnotationsProvider() {
      this.reactiveTemplateAnnotations.value = this.templateAnnotations
    }
  },
  provide() {
    return {
      config: this.config,
      templateAnnotations: this.reactiveTemplateAnnotations,
      templateDefinitions: this.templateDefinitions,
      pageTemplateId: this.pageTemplateId
    }
  }
}
</script>
