export {EditorContextHelper} from '@magnolia/template-annotations'

export const insertComments = (node, commentOpen, commentClose) => {
  if (node) {
    node.parentNode.insertBefore(document.createComment(commentOpen), node)
    node.parentNode.insertBefore(document.createComment(commentClose), node.nextSibling)
  }
}

function removeCommentElement(element) {
  if (element && element.nodeType === 8) {
    element.remove()
  }
}

export const removeComments = (node) => {
  if (!node) {
    return
  }
  removeCommentElement(node.previousSibling)
  removeCommentElement(node.nextSibling)
}

export const getProps = (node) => {
  const props = {}
  const metadata = {}

  Object.keys(node).forEach((key) => {
    if (key.match(/^(@|mgnl:|jcr:)/)) {
      metadata[key] = node[key]
    } else {
      props[key] = node[key]
    }
  })

  props.metadata = metadata
  props.key = node['@id']

  return props
}
