<template>
  <b-alert show variant="danger" class="d-flex">
    <b-icon icon="exclamation-triangle" class="flex-grow-0 pr-3" />
    <div class="font-condensed">
      <slot />
      <div v-if="code" class="mt-3">{{ 'errorCode' | translate(labels, 'shared_CommonErrors') }} {{ code }}</div>
    </div>
  </b-alert>
</template>

<script>
export default {
  conceptName: ['shared_CommonErrors'],
  props: {
    code: {
      type: Number,
      required: false
    }
  }
}
</script>
