<template>
  <div id="wrapper" class="w-100 d-flex flex-column flex-lg-row flex-grow-1 position-relative ">
    <SidebarMenu :loading="loading" :sections="availableSections" />

    <div id="content" class="overflow-hidden d-flex flex-column">
      <main class="d-flex flex-column flex-grow-1 flex-shrink-0 position-relative">
        <div v-if="loading" key="loading" class="bc-spinner" />
        <PageLoader v-else-if="availableSections.length" key="page" class="max-width-1000" />
        <div v-else key="no-pages-notification" class="p-5">
          <BNotification>{{ 'noPagesFound' | translate(labels, 'shared_CommonErrors') }}</BNotification>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import SidebarMenu from '@/components/common/SidebarMenu'
import {mapGetters, mapMutations} from 'vuex'
import PageLoader from '@/views/PageLoader'
import {inMgnl, inLocalhostEditor} from '@/vue-editor/advanced-helper'
import {apiService, magnoliaService} from '@/services'
import config from '@/config'
import BNotification from '@/components/ui/BNotification'

export const contractTypeToCodeMap = {'Full-Line': ['S', 'L']}

export default {
  name: 'BSoftware',
  conceptName: ['shared_CommonErrors', 'shared_Footer', 'shared_LanguagesISO'],
  components: {
    BNotification,
    PageLoader,
    SidebarMenu
  },
  data() {
    return {
      content: {},
      sections: [],
      loading: false,
      error: {present: false},
      inMagnolia: inMgnl
    }
  },
  computed: {
    ...mapGetters({
      getCurrentUser: 'software/getCurrentUser',
      getLanguage: 'software/getLanguage',
      getRegion: 'software/getRegion',
      getAvailableLanguages: 'software/getAvailableLanguages'
    }),
    availableSections() {
      const userContract = this.getCurrentUser?.contract
      if ((inMgnl || inLocalhostEditor) && userContract) {
        // populate additional codes
        for (const ct of userContract.types) {
          const additionalCodes = contractTypeToCodeMap[ct] || []
          additionalCodes.forEach(code => {
            if(userContract.codes.indexOf(code) === -1) userContract.codes.push(code)
          })
        }
        return this.sections.filter(s => {
          return !userContract.codes.length && !userContract.types.length
            || !s.contractCodes.length && !s.contractTypes.length
            || (s.contractCodes.some(r => userContract.codes.includes(r)) && !s.contractTypes.length)
            || (s.contractTypes.some(r => userContract.types.includes(r)) && !s.contractCodes.length)
            || (s.contractCodes.some(r => userContract.codes.includes(r)) && s.contractTypes.some(r => userContract.types.includes(r)))
        }
        )
      } else return this.sections
    },
    languages() {
      const ret = []
      const languageNames = this.labels['shared_LanguagesISO'] || {}
      let languageList = this.getAvailableLanguages
      if (languageList) {
        languageList.sort()
        if (languageList.indexOf('en') >= 0) {
          languageList = languageList.filter(item => item !== 'en')
          languageList.unshift('en')
        }
        for (const lang of languageList) {
          if (languageNames && languageNames[lang]) ret.push({value: lang, text: languageNames[lang]})
        }
      }
      return ret
    }
  },
  watch: {
    getRegion() {
      this.reloadPageNav()
    },
    getLanguage() {
      this.reloadPageNav()
    }
  },
  created() {
    this.loadPageNav()
  },
  methods: {
    ...mapMutations({
      saveLanguage: 'software/saveLanguage'
    }),
    reloadPageNav() {
      if (inMgnl) return
      this.loadPageNav()
    },
    async loadPageNav() {
      this.loading = true
      this.sections = []
      const service = inMgnl || inLocalhostEditor ? magnoliaService : apiService
      let region, language = null
      if (inMgnl) {
        const matcher = /\/owner-portal-(?<region>[a-z]{2,6})\/.*(?<language>[a-z]{2})\/(?<path>.+)/
        const found = this.$route.path.match(matcher)
        if (found) {
          region = found.groups.region.toUpperCase()
          language = found.groups.language
        } else {
          this.error = true
        }
      }

      region = region || this.getRegion || 'NA'
      language = language || this.getLanguage

      service.fetchPageNav(region, language).then(response => {
        const pages = response.data
        for(let node of pages['@nodes']) {
          this.sections.push({
            key: config.publicPath + '/' + node,
            title: pages[node].title,
            disabled: inMgnl,
            contractCodes: pages[node].contractCodes?.map(i => i['@name']) || [],
            contractTypes: pages[node].contractTypes?.map(i => i.displayName) || []
          })
        }
        if (!inMgnl && this.sections.reduce((acc, s) => acc.concat(s.key), []).indexOf(this.$route.path) < 0) this.$router.push(this.sections[0].key)
        this.loading = false
      }).catch(() => {
        this.error = true
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
@import 'src/scss/z-index';
@import 'src/scss/grid';
@import 'src/scss/colors';
@import 'src/scss/transitions';
#wrapper {
  padding-top: 4rem;
}
iframe {
  max-width: 100%;
}
#content {
  flex: 1;
  main {
    & > section {
      padding: 1rem 1rem;
    }
    .dropdown-toggle {
      white-space: normal;
      &:after {
        font-size: 1.2rem;
        transition: $transition-base;
      }
      &[aria-expanded="true"] {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}
@include media-breakpoint-up('xl') {
  #content {
    main {
      & > section {
        padding: 2rem 3rem;
      }
    }
  }
}
.max-width-1000 {
  max-width: 60rem;
}
</style>
