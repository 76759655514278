import api from '@/api'

export default function (server) {
  server.get(location.protocol + api.getEndpoint(api.endpoints.API.FEATURE, {feature: ':path'}), (schema, request) => {
    let {feature} = request.params
    return {
      'name': feature,
      'enabled': true
    }
  })

  server.get(location.protocol + api.endpoints.API.FEATURES, () => {
    return [
      {'name': 'mymachineAppNA', 'enabled': true},
      {'name': 'mymachineAppEMEA', 'enabled': true},
      {'name': 'supportAppNA', 'enabled': true},
      {'name': 'supportAppEMEA', 'enabled': true},
      {'name': 'softwareAppNA', 'enabled': true},
      {'name': 'softwareAppEMEA', 'enabled': true},
      {'name': 'usbOrderFormNA', 'enabled': true},
      {'name': 'noSerialFormNA', 'enabled': true},
      {'name': 'newGuideFormNA', 'enabled': true}]
  })
}
