import api from '@/api'
import appConfig from '@/config'

export const inLocalhostEditor = appConfig.nodeEnv === 'mgnl' || appConfig.nodeEnv === 'mgnlapi'
const inMgnlHost = window.location.host === api.urls.MAGNOLIA_AUTHOR.slice(2, -1)
const inIframe =
  typeof window !== 'undefined' ? window.self !== window.top : false
export const inMgnlIframe =
  inIframe &&
  window !== 'undefined' &&
  window.location.search.includes('mgnlPreview')
export const inEditorEdit =
  inIframe && window.location.search.includes('mgnlPreview=false')

export const inMgnl = inMgnlIframe || inMgnlHost

export default {}
