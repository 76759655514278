const dev = require('./apiUrls.dev')
const prod = require('./apiUrls.prod')
const qa = require('./apiUrls.qa')
import appConfig from '../config'

let apiEndpoints = require('./apiEndpoints')
let endpoints = {}
let urls = {}

switch (appConfig.nodeEnv) {
case 'development': urls = dev; break
case 'qa': urls = qa; break
case 'prod': urls = prod; break
case 'mgnl' : urls = dev; break
case 'mgnlapi' : urls = dev; break
default: urls = prod; break
}

//populate api urls in endpoints
for (const apiName in apiEndpoints) {
  endpoints[apiName] = {}
  for (const endpoint in apiEndpoints[apiName]) {
    if (apiName in urls) {
      endpoints[apiName][endpoint] = urls[apiName] + apiEndpoints[apiName][endpoint]
    } else {
      throw Error('Missing api url declaration for ' + apiName)
    }
  }
}

/**
 * Validate and replace params in endpoint string
 *
 * @param {string} endpoint Endpoint url with placeholders
 * @param {Object} params Params to replace
 * @returns {string} endpoint Endpoint url with replaced placeholders
 */
const getEndpoint = function (endpoint, params) {
  const placeholders = endpoint.match(/(\{.*?\})/g)
  for (const param of placeholders) {
    const key = param.slice(1, -1)
    if (key in params) {
      endpoint = endpoint.replace(param, params[key])
    } else {
      throw Error('Missing endpoint url param value for ' + key)
    }
  }
  return endpoint
}

export default {urls, endpoints, getEndpoint}
