<template>
  <span class="bicon d-inline-block" @click="$emit('click', $event)">
    <bicon :class="iconObject" />
  </span>
</template>

<script>
export default {
  props: {
    icon: {
      required: true
    },
    fixedWidth: {
      required: false,
      default: false
    },
    flip: {
      type: String,
      required: false
    },
    rotation: {
      type: String,
      required: false
    }
  },
  computed: {
    iconObject() {
      if (this.icon === '~~~~~') return 'spinner'
      try {
        return JSON.parse(this.icon)
      } catch (e) {
        return this.icon
      }
    }
  }
}
</script>
