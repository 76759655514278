<template>
  <div>
    <b-modal
      id="region-selector-modal"
      centered
      lazy
      ok-only
      hide-header-close
      no-close-on-backdrop
      :ok-title="translatedLabel('close', 'shared_CommonFunctional')"
      header-bg-variant="white"
      footer-bg-variant="white"
    >
      <template slot="modal-title">{{ 'selectRegion' | translate(labels, 'shared_Regions') }}</template>
      <div class="mt-0 mt-md-4">
        <div v-if="regions.length === 0" class="text-center px-3 mt-1 mt-md-0">
          {{ 'emptyRegions' | translate(labels, 'shared_Regions') }}
        </div>
        <b-form-group class="d-flex justify-content-center mb-0">
          <b-form-radio
            v-for="region in regions"
            :key="region.key"
            v-model="selectedRegion"
            name="region-radio"
            :value="region.key"
            class="my-3"
          >
            {{ region.translation }}
          </b-form-radio>
        </b-form-group>
      </div>
      <template #modal-footer>
        <div class="text-right d-flex flex-wrap d-md-block w-100">
          <b-button
            class="mt-2 mt-md-0 ml-md-3 order-1 col-12 col-md-auto"
            variant="outline-primary"
            @click="logout()"
          >
            {{ 'cancelAndLogout' | translate(labels, 'shared_CommonFunctional') }}
          </b-button>
          <b-button
            type="submit"
            variant="primary"
            class="mt-2 mt-md-0 ml-md-3 order-0 col-12 col-md-auto d-inline-flex justify-content-center align-items-center"
            :disabled="regions.length === 0"
            @click="setRegion()"
          >
            {{ 'setRegion' | translate(labels, 'shared_Regions') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  conceptName: ['shared_CommonFunctional', 'shared_Regions'],
  name: 'RegionSelectorModal',
  props: {
    regions: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    selectedRegion: ''
  }),
  mounted() {
    this.selectedRegion = this.regions[0] && this.regions[0].key
    this.$bvModal.show('region-selector-modal')
  },
  methods: {
    setRegion() {
      this.$emit('select', this.selectedRegion)
      this.$bvModal.hide('region-selector-modal')
    },
    async logout() {
      if (this.$auth) await this.$auth.signOut()
    }
  }
}
</script>
