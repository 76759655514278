import {EditorContextHelper} from './helper'

export function reloadMgnl() {
  setTimeout(() => {
    EditorContextHelper.onFrameReady()
    EditorContextHelper.refresh()
  })
}

const EditableMixin = {
  updated() {
    reloadMgnl()
  },
  mounted() {
    reloadMgnl()
  }
}
export default EditableMixin
