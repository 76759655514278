import axios from '@/axios'
import api from '@/api'
import config from '@/config'

const CancelToken = axios.CancelToken
let cancelFn = {}

export const contentService = {
  fetchRegionTranslations,
  fetchBackupTranslations,
  fetchFaqSections,
  fetchFaqContent,
  fetchSitemap,
  fetchDownloadLink
}

function fetchRegionTranslations(_region) {
  return axios.get(api.endpoints.CONTENT_API.REGION_TRANSLATIONS.replace('{region}', _region))
}

function fetchBackupTranslations(_region) {
  return axios.get(config.myMachinePath + '/translations/{region}.json'.replace('{region}', _region))
}

function fetchFaqSections(_path) {
  return axios.get(api.endpoints.CONTENT_API.FAQ_SECTIONS.replace('{directory}', _path))
}

function fetchFaqContent(_path, _section) {
  if (typeof cancelFn['fetchFaqContent'] === 'function') cancelFn['fetchFaqContent']('isCancelled')
  return axios.get(api.endpoints.CONTENT_API.FAQ_CONTENT.replace('{directory}', _path).replace('{sectionKey}', _section), {
    cancelToken: new CancelToken(function executor(c) {
      cancelFn['fetchFaqContent'] = c
    })
  })
}

function fetchSitemap() {
  return axios.get(api.endpoints.CONTENT_API.SITEMAP)
}

function fetchDownloadLink(id, key, lang) {
  return axios.get(api.endpoints.CONTENT_API.DOWNLOAD_LINK.replace('{reference}', `${id}@${key}`), {
    params: {lang}
  })
}
