export const initialState = {
  stateRestored: false,
  showConceptNames: false,
  features: {},
  user: {},
  regionConcepts: {},
  identity: null,
  language: {},
  region: {}
}

export default initialState
