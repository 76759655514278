<template>
  <b-modal id="profile-modal" ref="profileModal" title="Product family line available for user">
    <div class="px-3 px-md-5">
      <p>Select products available for user to simulate page visibility</p>
      <b-row>
        <b-col>
          Available products
          <b-form-select v-model="availableHighlighted.codes" multiple :options="available.codes" :select-size="8" />
        </b-col>
        <b-col cols="1" class="d-flex align-items-center flex-column justify-content-center">
          <div><b-button variant="primary" @click="select(availableHighlighted.codes, 'codes')">&rsaquo;</b-button></div>
          <div class="mt-2"><b-button variant="primary" @click="deselect(selectedHighlighted.codes, 'codes')">&lsaquo;</b-button></div>
        </b-col>
        <b-col>
          Selected products
          <b-form-select v-model="selectedHighlighted.codes" multiple :options="selected.codes" :select-size="8" />
        </b-col>
      </b-row>
    </div>
    <template #modal-footer>
      <div class="text-right d-flex flex-wrap d-md-block w-100">
        <b-button
          class="mt-2 ml-md-3 order-1 col-12 col-md-auto"
          variant="outline-primary"
          @click="closeModal()"
        >
          {{ 'closeButton' | translate(labels, 'shared_CommonFunctional') }}
        </b-button>
        <b-button type="submit" variant="primary" class="mt-2 ml-md-3 order-0 col-12 col-md-auto d-inline-flex justify-content-center align-items-center" @click="onApply">{{ 'applyButton' | translate(labels, 'shared_CommonFunctional') }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {mapGetters} from 'vuex'
import {magnoliaService} from '@/services'

export default {
  props: {
    disabled: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      availableHighlighted: {
        codes: [],
        types: []
      },
      selectedHighlighted: {
        codes: [],
        types: []
      },
      selected: {
        codes: [],
        types: []
      },
      contract: {
        codes: [],
        types: []
      }
    }
  },
  computed: {
    ...mapGetters({
      getCurrentUser: 'software/getCurrentUser'
    }),
    available() {
      return {
        codes: this.contract.codes.filter(x => !this.selected.codes.includes(x)),
        types: this.contract.types.filter(x => !this.selected.types.includes(x))
      }
    }
  },
  mounted() {
    this.loadCategories()
  },
  methods: {
    loadCategories() {
      magnoliaService.fetchCategory('dealer-contract-codes').then(response => {
        if (Array.isArray(response.data)) this.contract.codes = response.data.map(item => { return {value: item['@name'], text: item.displayName}})
      })
      magnoliaService.fetchCategory('dealer-contract-types').then(response => {
        if (Array.isArray(response.data)) this.contract.types = response.data.map(item => { return {value: item['displayName'], text: item.displayName}})
      })
    },
    select(elements, category) {
      if (elements.length) {
        for (const value of elements) this.selected[category].push(this.contract[category].find(i => i.value === value))
        this.selected[category].sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
        this.availableHighlighted[category] = []
      }
    },
    deselect(elements, category) {
      if (elements.length) {
        for (const value of elements) this.selected[category] = this.selected[category].filter(i => i.value !== value)
        this.selected[category].sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
        this.selectedHighlighted[category] = []
      }
    },
    show() {
      this.$refs.profileModal.show()
    },
    onApply() {
      const user = this.getCurrentUser
      if (this.selected.codes.length || this.selected.types.length) {
        user.contract = {
          codes: this.selected.codes.map(i => i.value),
          types: this.selected.types.map(i => i.value)
        }
        const allContracts = [user.contract.codes.join(', '), user.contract.types.join(', ')].filter(x => !!x)
        user.name = 'Impersonated - ' + allContracts.join(' | ')
      } else {
        user.name = 'Impersonated - all access'
        user.contract = {codes: [], types: []}
      }
      this.$store.commit('software/saveUser', user)
      this.$refs.profileModal.hide()
    },
    closeModal() {
      this.$refs.profileModal.hide()
    }
  }
}
</script>

<style lang="scss" scoped>

.maxw-18 {
  width: 100%;
  max-width: 18rem;
}
</style>
