<template>
  <section class="mb-4">
    <overlay-wrapper :show="loading">
      <BError v-if="error">{{ 'errorLoadingPage' | translate(labels,'software_SoftwareComponent') }}</BError>
      <BError v-else-if="!isSiteAvailable && !loading">{{ 'unauthorizedSection' | translate(labels,'shared_CommonErrors') }}</BError>
      <EditablePage
        v-else-if="content"
        :content="content"
        :config="config"
        :template-definitions="templateDefinitions"
      />
    </overlay-wrapper>
  </section>
</template>

<script>
import magnoliaConfig from '../magnolia.config'
import EditablePage from '@/vue-editor/EditablePage'
import BError from '@/components/ui/BError'
import {inEditorEdit, inMgnl, inLocalhostEditor} from '@/vue-editor/advanced-helper'
import {apiService, magnoliaService} from '@/services'
import {mapGetters, mapMutations} from 'vuex'
import config from '@/config'
import contractTypeToCodeMap from './SoftwarePage'

export default {
  name: 'PageLoader',
  components: {EditablePage, BError},
  data: function () {
    return {
      content: null,
      config: magnoliaConfig,
      templateDefinitions: {},
      error: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      getCurrentUser: 'software/getCurrentUser',
      getRegion: 'software/getRegion',
      getLanguage: 'software/getLanguage'
    }),
    isSiteAvailable() {
      const userContract = this.getCurrentUser?.contract
      const pageContractCodes = this.content?.contractCodes?.map(i => i['@name']) || []
      const pageContractTypes = this.content?.contractTypes?.map(i => i.displayName) || []
      if ((inMgnl || inLocalhostEditor) && userContract) {
        // populate additional codes
        for (const ct of userContract.types) {
          const additionalCodes = contractTypeToCodeMap[ct] || []
          additionalCodes.forEach(code => {
            if(userContract.codes.indexOf(code) === -1) userContract.codes.push(code)
          })
        }
        return !userContract.codes.length && !userContract.types.length
          || (!pageContractCodes.length || pageContractCodes.some(r => userContract.codes.includes(r)))
          && (!pageContractTypes.length || pageContractTypes.some(r => userContract.types.includes(r)))
      } else return true
    }
  },
  watch: {
    $route() {
      this.reloadPage()
    },
    getRegion() {
      this.reloadPage()
    },
    getLanguage() {
      this.reloadPage()
    }
  },
  mounted() {
    this.loadPage()
  },
  methods: {
    ...mapMutations({
      saveLanguage: 'software/saveLanguage'
    }),
    reloadPage() {
      if (inMgnl) return
      this.loadPage()
    },
    async loadPage() {
      this.error = false
      this.content = null
      let path, region, language = null
      /* eslint-disable no-console */
      console.log('inMgnl', inMgnl)
      if (inMgnl) {
        const matcher = /\/owner-portal-(?<region>[a-z]{2,6})\/.*(?<language>[a-z]{2})\/(?<path>.+)/
        const found = this.$route.path.match(matcher)
        console.log('found', found)
        if (found) {
          region = found.groups.region.toUpperCase()
          language = found.groups.language
          console.log('region', region)
          console.log('language', language)
          path = found.groups.path
        } else {
          this.error = true
        }
      } else {
        if (this.$route) path = this.$route.path.replace(config.publicPath + '/', '')
      }

      region = region || this.getRegion
      language = language || this.getLanguage

      if (path && path !== '/') {
        this.loading = true
        const service = inMgnl || inLocalhostEditor ? magnoliaService : apiService
        console.log('loading path', path, region, language)
        try {
          const content = await service.fetchPage(path, region, language)
          if (inEditorEdit) {
            console.log('inEditorEdit', inEditorEdit)
            const templateDefinitions = await magnoliaService.fetchTemplateDefinition(content.data['mgnl:template'])
            console.log('templateDefinitions', templateDefinitions.data)
            this.templateDefinitions = templateDefinitions.data
          }
          this.content = content.data
          this.loading = false
        } catch (e) {
          console.log('error', e)
          if (e.message !== 'isCancelled') {
            this.error = true
            this.loading = false
          }
        }
      }
    }
  }
}
</script>
