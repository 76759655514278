export default {
  'dp-software:pages/index': {
    'dialog': 'dp-software:pages/index',
    'templateScript': 'https://templateScriptUrlPlaceholder',
    'name': 'index',
    'areas': {
      'main': {
        'name': 'main',
        'availableComponents': {
          'SectionRTE': {'id': 'dp-software:components/sectionRTE', 'enabled': true},
          'SectionDownload': {'id': 'dp-software:components/sectionDownload', 'enabled': true}
        },
        'title': 'Main area'
      }
    },
    'id': 'dp-software:pages/index',
    'type': 'content',
    'title': 'Dealer Portal Service Software SPA page',
    'renderType': 'spaExtended'
  },
  'dp-software:components/sectionRTE': {
    'dialog': 'dp-software:components/sectionRTE',
    'name': 'sectionRTE',
    'id': 'dp-software:components/sectionRTE',
    'type': 'content',
    'title': 'Rich Text Editor Section'
  },
  'dp-software:components/sectionDownload': {
    'dialog': 'dp-software:components/sectionDownload',
    'name': 'sectionDownload',
    'id': 'dp-software:components/sectionDownload',
    'type': 'content',
    'title': 'Download Section'
  }
}
