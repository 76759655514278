export default {
  '@name': 'na',
  '@path': '/dp-software/na',
  '@id': '438c70f7-d0f7-4652-b0d7-1d874dcaad30',
  '@nodeType': 'mgnl:page',
  'title': 'NA',
  'templateScript': 'https://develop.bobcatdealernet.dev.dice-tools.com/service/software/',
  'ServiceAnalyzer': {
    '@name': 'ServiceAnalyzer',
    '@path': '/dp-software/na/ServiceAnalyzer',
    '@id': '13190dbf-da6d-48ff-92b5-653f34bffbc9',
    '@nodeType': 'mgnl:page',
    'heading': 'Diagnostic software designed to interact with Bobcat machines for performing diagnostic testing, monitoring critical components, viewing current machine performance, installation of dealer information in machine controller and updating software for selected controllers.',
    'contractTypes': [],
    'contractCodes': [{
      '@name': 'M',
      '@path': '/dealer-portal/dealer-contract-codes/M',
      '@id': '8b8d2ec1-c4e6-4891-8a95-e5d085bb07dd',
      '@nodeType': 'mgnl:category',
      'displayName': 'M: Loader Mini Track',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/M/relatedUUID',
        '@id': '2317e373-11fb-41a0-a7d6-11b97839eed1',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'F',
      '@path': '/dealer-portal/dealer-contract-codes/F',
      '@id': 'c208cabf-b52b-4446-bd6b-dda912e1e420',
      '@nodeType': 'mgnl:category',
      'displayName': 'F: Loader Articulated',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/F/relatedUUID',
        '@id': 'addc118c-0b37-411f-a5f8-ca2ea9f37371',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'Q',
      '@path': '/dealer-portal/dealer-contract-codes/Q',
      '@id': 'ad4932bd-2e0f-4789-ae75-42d6972bb204',
      '@nodeType': 'mgnl:category',
      'displayName': 'Q: Compaction',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/Q/relatedUUID',
        '@id': '3e450654-8440-4a02-9dac-ebee0e1cbfe6',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'D',
      '@path': '/dealer-portal/dealer-contract-codes/D',
      '@id': 'e2732676-8c7d-4612-9238-8ddb3133f403',
      '@nodeType': 'mgnl:category',
      'displayName': 'D: Loader Articulated',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/D/relatedUUID',
        '@id': '5c25ee91-2375-4387-9692-dbc6c177a818',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'S',
      '@path': '/dealer-portal/dealer-contract-codes/S',
      '@id': '637045ce-5d7f-49bd-bc24-a032933d330d',
      '@nodeType': 'mgnl:category',
      'displayName': 'S: Excavator',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/S/relatedUUID',
        '@id': '6cd4a3ca-440c-4c70-9e2c-bd174d363f55',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'T',
      '@path': '/dealer-portal/dealer-contract-codes/T',
      '@id': 'e75e0cf7-3f57-4717-a8d8-df8d34ab1f43',
      '@nodeType': 'mgnl:category',
      'displayName': 'T: Loader',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/T/relatedUUID',
        '@id': '3c3759f7-bd87-4a13-ae6a-0e392c66a355',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'U',
      '@path': '/dealer-portal/dealer-contract-codes/U',
      '@id': 'aad98eaf-e02c-409b-aded-9b325f7de5f6',
      '@nodeType': 'mgnl:category',
      'displayName': 'U: Toolcat',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/U/relatedUUID',
        '@id': '942215b5-5db9-4175-bb88-0eb7519a1fe1',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'G',
      '@path': '/dealer-portal/dealer-contract-codes/G',
      '@id': '184cdfee-f3dc-4685-8922-f29b51b8705a',
      '@nodeType': 'mgnl:category',
      'displayName': 'G: Compact Tractor',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/G/relatedUUID',
        '@id': '9d508a11-c45c-42e1-b01e-780b0bbd1ddc',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'R',
      '@path': '/dealer-portal/dealer-contract-codes/R',
      '@id': 'bd0b90d2-08bf-41d9-b369-493ef7104e7a',
      '@nodeType': 'mgnl:category',
      'displayName': 'R: Excavator',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/R/relatedUUID',
        '@id': '8d93b881-244e-408f-bdb2-ffb12ff8632d',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'L',
      '@path': '/dealer-portal/dealer-contract-codes/L',
      '@id': 'deab1c0e-908b-41f5-958c-b2b5675e6eb3',
      '@nodeType': 'mgnl:category',
      'displayName': 'L: Backhoe Loader',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/L/relatedUUID',
        '@id': 'f6eaa5ab-1c77-4a10-861d-3d058e1605f7',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'N',
      '@path': '/dealer-portal/dealer-contract-codes/N',
      '@id': 'b9ad2aa2-47fa-4785-9961-d052d05f153d',
      '@nodeType': 'mgnl:category',
      'displayName': 'N: Portable Power',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/N/relatedUUID',
        '@id': '6f8f34a6-2436-41ce-9388-a4ad6412e8d1',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'P',
      '@path': '/dealer-portal/dealer-contract-codes/P',
      '@id': '9d485b0f-ecd4-41bb-a534-d95a98b14dcd',
      '@nodeType': 'mgnl:category',
      'displayName': 'P: Utility Vehicle',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/P/relatedUUID',
        '@id': '0cb7e16b-585c-4bec-89f1-e3ffb2a7353a',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'E',
      '@path': '/dealer-portal/dealer-contract-codes/E',
      '@id': 'cb20c725-9083-4050-bd09-5201bea4e042',
      '@nodeType': 'mgnl:category',
      'displayName': 'E: Loader',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/E/relatedUUID',
        '@id': '10d57a49-cf6e-4b73-9203-4bacedef0890',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'V',
      '@path': '/dealer-portal/dealer-contract-codes/V',
      '@id': '42635f10-0fd5-40cb-9ae7-629b7e2c2870',
      '@nodeType': 'mgnl:category',
      'displayName': 'V: Telescopic Handler',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/V/relatedUUID',
        '@id': '9b3ec408-3bc9-4169-80cf-b67c121a5b09',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }],
    'title': 'ServiceAnalyzer (w/o W, H, K)',
    'templateScript': 'https://develop.bobcatdealernet.dev.dice-tools.com/service/software/',
    '@nodes': []
  },
  'ServiceAnalyzer-cc': {
    '@name': 'ServiceAnalyzer-cc',
    '@path': '/dp-software/na/ServiceAnalyzer-cc',
    '@id': 'e0e8d527-7ac8-480f-a894-6ef8b783c16b',
    '@nodeType': 'mgnl:page',
    'heading': 'Diagnostic software designed to interact with Bobcat machines for performing diagnostic testing, monitoring critical components, viewing current machine performance, installation of dealer information in machine controller and updating software for selected controllers.',
    'contractCodes': [{
      '@name': 'H',
      '@path': '/dealer-portal/dealer-contract-codes/H',
      '@id': '5dc88f2a-706d-4cb0-8a6d-7f79b41ebc98',
      '@nodeType': 'mgnl:category',
      'displayName': 'H: Mower',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/H/relatedUUID',
        '@id': '56603ac8-81c1-4e84-ba6a-9a9f4fdf316e',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'K',
      '@path': '/dealer-portal/dealer-contract-codes/K',
      '@id': '6a8f6b29-205b-4f47-8bc9-737cefc074cd',
      '@nodeType': 'mgnl:category',
      'displayName': 'K: Mower',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/K/relatedUUID',
        '@id': '2604aaca-1203-4f74-9588-2f8fbbf7e87b',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'W',
      '@path': '/dealer-portal/dealer-contract-codes/W',
      '@id': '56f091ba-b667-4295-884f-74037b3e548c',
      '@nodeType': 'mgnl:category',
      'displayName': 'W: Mower',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/W/relatedUUID',
        '@id': '4f76ab87-2abf-4b72-990f-93c7d454ba1a',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }],
    'title': 'ServiceAnalyzer Mowers (W, H, K)',
    'templateScript': 'https://feature-kp-1877-direct-int.bobcatdealernet.dev.dice-tools.com/service/software',
    '@nodes': []
  },
  'SoftwareNA': {
    '@name': 'SoftwareNA',
    '@path': '/dp-software/na/SoftwareNA',
    '@id': 'cdd88e12-664b-4de5-aab9-6a579e3ce6e2',
    '@nodeType': 'mgnl:page',
    'contractCodes': [],
    'title': 'Software only in NA (all CC)',
    'templateScript': 'https://develop.bobcatdealernet.dev.dice-tools.com/service/software/',
    '@nodes': []
  },
  'Software2': {
    '@name': 'Software2',
    '@path': '/dp-software/na/Software2',
    '@id': '7034bfee-a89e-4def-8b36-32efd43758dd',
    '@nodeType': 'mgnl:page',
    'heading': 'Heading test',
    'contractCodes': [],
    'title': 'Software in NA (all CC)',
    'templateScript': 'https://develop.bobcatdealernet.dev.dice-tools.com/service/software/',
    '@nodes': []
  },
  'Loader-only': {
    '@name': 'Loader-only',
    '@path': '/dp-software/na/Loader-only',
    '@id': 'a267fb6e-5fb5-4fe4-b62c-9bf6608f830a',
    '@nodeType': 'mgnl:page',
    'heading': 'Loader only test page (E code)',
    'contractCodes': [{
      '@name': 'E',
      '@path': '/dealer-portal/dealer-contract-codes/E',
      '@id': 'cb20c725-9083-4050-bd09-5201bea4e042',
      '@nodeType': 'mgnl:category',
      'displayName': 'E: Loader',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/E/relatedUUID',
        '@id': '10d57a49-cf6e-4b73-9203-4bacedef0890',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }],
    'title': 'Loader only (E only)',
    'templateScript': 'https://develop.bobcatdealernet.dev.dice-tools.com/service/software/',
    '@nodes': []
  },
  'test-gd': {
    '@name': 'test-gd',
    '@path': '/dp-software/na/test-gd',
    '@id': 'ef84c2f2-0daa-4ffd-a071-a47d29cb8602',
    '@nodeType': 'mgnl:page',
    'heading': 'Lorem ipsum mowers only',
    'contractCodes': [{
      '@name': 'H',
      '@path': '/dealer-portal/dealer-contract-codes/H',
      '@id': '5dc88f2a-706d-4cb0-8a6d-7f79b41ebc98',
      '@nodeType': 'mgnl:category',
      'displayName': 'H: Mower',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/H/relatedUUID',
        '@id': '56603ac8-81c1-4e84-ba6a-9a9f4fdf316e',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'K',
      '@path': '/dealer-portal/dealer-contract-codes/K',
      '@id': '6a8f6b29-205b-4f47-8bc9-737cefc074cd',
      '@nodeType': 'mgnl:category',
      'displayName': 'K: Mower',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/K/relatedUUID',
        '@id': '2604aaca-1203-4f74-9588-2f8fbbf7e87b',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }, {
      '@name': 'W',
      '@path': '/dealer-portal/dealer-contract-codes/W',
      '@id': '56f091ba-b667-4295-884f-74037b3e548c',
      '@nodeType': 'mgnl:category',
      'displayName': 'W: Mower',
      'level': 'level-1',
      'relatedUUID': {
        '@name': 'relatedUUID',
        '@path': '/dealer-portal/dealer-contract-codes/W/relatedUUID',
        '@id': '4f76ab87-2abf-4b72-990f-93c7d454ba1a',
        '@nodeType': 'mgnl:contentNode',
        '@nodes': []
      },
      '@nodes': ['relatedUUID']
    }],
    'title': 'Mowers only (W, H, K)',
    'templateScript': 'https://feature-kp-2008.bobcatdealernet.dev.dice-tools.com/service/software/',
    '@nodes': []
  },
  'Ground-Maintenance-only': {
    '@name': 'Ground-Maintenance-only',
    '@path': '/dp-software/na/Ground-Maintenance-only',
    '@id': 'f10325a3-2c76-4670-a5d3-3fa1f0f2af2a',
    '@nodeType': 'mgnl:page',
    'heading': 'Grounds Maintenance only',
    'contractTypes': [{
      '@name': 'Grounds-Maintenance',
      '@path': '/dealer-portal/dealer-contract-types/Grounds-Maintenance',
      '@id': 'e97ce40c-217e-4f89-bd80-2fd57f0cfc60',
      '@nodeType': 'mgnl:category',
      'name': 'Grounds Maintenance',
      'level': 'level-1',
      'displayName': 'Grounds Maintenance',
      '@nodes': []
    }],
    'contractCodes': [],
    'title': 'Grounds Maintenance only',
    'templateScript': 'https://develop.bobcatdealernet.dev.dice-tools.com/service/software/',
    '@nodes': []
  },
  '@nodes': ['ServiceAnalyzer', 'ServiceAnalyzer-cc', 'SoftwareNA', 'Software2', 'Loader-only', 'test-gd', 'Ground-Maintenance-only']
}
