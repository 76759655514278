<template>
  <div class="p-3 bg-gray-100 mb-3">
    <b-row>
      <b-col cols="12" md="">
        <h2>{{ $attrs.titleField || metadata['@name'] }}</h2>
        <p>{{ $attrs.subtitleField }}</p>
      </b-col>
      <b-col cols="12" md="" class="text-md-right mb-3 mb-md-0">
        <template v-for="(link, i) in documentDownloads">
          <div :key="'documentDownload_' + i">
            <b-link v-if="documentLink(link.softwareDownloadDocument)" class="font-condensed" :href="documentLink(link.softwareDownloadDocument)" target="_blank"><b-icon :icon="link.icon" class="mr-1 text-large" />{{ link.label }}</b-link>
          </div>
        </template>
        <template v-for="(link, i) in releaseNotes">
          <div :key="'releaseNotes_' + i">
            <b-link class="font-condensed" :href="link.url" target="_blank"><b-icon icon="bobcaticon-file" class="mr-1 text-large" />{{ link.label }}</b-link>
          </div>
        </template>
      </b-col>
    </b-row>

    <template v-for="(link, i) in softwareItems">
      <SoftwareLink :key="'software' + i" :link="link" @error="errorSoftware = true" @releaseNotes="addReleaseNotes" />
    </template>

    <BNotification v-if="inMgnl">{{ 'mockedData' | translate(labels, 'software_SoftwareComponent') }}</BNotification>

    <BError v-if="errorSoftware">{{ 'commonError' | translate(labels, 'shared_CommonErrors') }}</BError>

    <BError v-if="error">{{ 'downloadError' | translate(labels, 'shared_CommonErrors') }}</BError>
  </div>
</template>

<script>
import getCloudinaryMeta from '@/helpers/cloudinaryUtils'
import BError from '@/components/ui/BError'
import BNotification from '@/components/ui/BNotification'
import {mapGetters} from 'vuex'
import SoftwareLink from './AutomatedDownload/SoftwareLink'
import {inMgnl} from '@/vue-editor/advanced-helper'

export default {
  name: 'AutomatedDownload',
  components: {BNotification, BError, SoftwareLink},
  props: ['title', 'metadata'],
  data() {
    return {
      error: false,
      errorSoftware: false,
      releaseNotes: [],
      inMgnl: inMgnl
    }
  },
  computed: {
    ...mapGetters({
      getLanguage: 'software/getLanguage'
    }),
    documentDownloads() { return this.$attrs?.documentDownloads?.['@nodes'].map(name => this.$attrs?.documentDownloads?.[name]) || [] },
    softwareItems() { return this.$attrs?.softwareItems?.['@nodes'].map(name => this.$attrs?.softwareItems?.[name]) || [] }
  },
  methods: {
    addReleaseNotes(link) {
      this.releaseNotes.push(link)
    },
    documentLink(cloudinaryLink) {
      const meta = getCloudinaryMeta(cloudinaryLink)
      return meta.secure_url || false
    }
  }
}
</script>
