<template>
  <overlay-wrapper :show="loading">
    <template v-if="loading">
      <div v-for="software in [1,2]" :key="software" class="software-link p-3 border1 bg-white mb-2">
        <div class="d-flex">
          <div class="mr-2 d-flex align-items-center">
            <img src="./../../../assets/icons/service-analyzer.svg" width="34" height="34">
          </div>
          <div class="software-link-title font-condensed">
            {{ link.softwareLabel
              .replace('[software]', link.softwareCategory.displayName)
              .replace('[version]', '~')
              .replace('[release]', '~') }}
          </div>
        </div>
      </div>
    </template>

    <div v-for="software in softwares" :key="software.version" class="software-link cursor-pointer p-3 border1 bg-white mb-2" @click="downloadFile(software.download_link)">
      <div class="d-flex">
        <div v-if="icon" class="mr-2 d-flex align-items-center">
          <img :src="icon" width="34" height="34">
        </div>
        <div class="software-link-title font-condensed">
          {{ link.softwareLabel
            .replace('[software]', link.softwareCategory.displayName)
            .replace('[version]', software.version)
            .replace('[release]', software.releaseDate) }}
        </div>
      </div>
    </div>
  </overlay-wrapper>
</template>

<script>
import {apiService} from '../../../services'
import {mapGetters} from 'vuex'

export default {
  name: 'SoftwareLink',
  props: ['link'],
  data() {
    return {
      softwares: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      getLanguage: 'software/getLanguage'
    }),
    icon() {
      const camelizedSoftware = this.link.softwareCategory.displayName.charAt(0).toLowerCase() + this.link.softwareCategory.displayName.slice(1)
      const concepts = this.$store.getters['software/getConcepts']
      let softwareConcepts = concepts['mymachine_Software'] || {}
      if (softwareConcepts) {
        const link = /(https?:\/\/[^" ]*)/i
        const match = Object.keys(softwareConcepts).find(label => label === camelizedSoftware + 'Icon')
        if (match) {
          const imageSrc = softwareConcepts[match].match(link)
          if (Array.isArray(imageSrc)) return imageSrc[0]
        }
      }
      return false
    },
    localeCode() {
      return this.labels?.shared_LocalesKebabCase?.[this.getLanguage] || 'en-US'
    }
  },
  created() {
    this.loading = true
    apiService.fetchSoftware(this.link.softwareCategory.displayName, this.localeCode).then(response => {
      for (const item of response.data) {
        const releaseDate = item.release_date ? this.moment(item.release_date).toDate().toLocaleDateString() : ''
        if (item.type === 'releasenotes') {
          this.$emit('releaseNotes', {
            label: this.link.releaseNoteLabel
              .replace('[software]', item.software_type)
              .replace('[version]', item.version)
              .replace('[release]', releaseDate),
            url: item.download_link
          })
        } else {
          item.releaseDate = releaseDate
          this.softwares.push(item)
        }
      }
      this.softwares.sort((a, b) => this.compareVersion(a.version, b.version))
    }).catch(e => {
      /* eslint-disable no-console */
      console.log(e)
      this.$emit('error')
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    compareVersion(v1, v2) {
      if (typeof v1 !== 'string' || typeof v2 !== 'string') return false
      v1 = v1.split('.')
      v2 = v2.split('.')
      const k = Math.min(v1.length, v2.length)
      for (let i = 0; i < k; ++ i) {
        v1[i] = parseInt(v1[i], 10)
        v2[i] = parseInt(v2[i], 10)
        if (v1[i] > v2[i]) return -1
        if (v1[i] < v2[i]) return 1
      }
      return v1.length === v2.length ? 0 : (v1.length < v2.length ? 1 : -1)
    },
    downloadFile(url) {
      const link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }
  }
}
</script>
