import {OktaAuth} from '@okta/okta-auth-js'
import appConfig from '@/config'
import {inMgnl} from '@/vue-editor/advanced-helper'
let oktaAuth = {}

if (!inMgnl && process.env.VUE_APP_OFFLINE !== 'true') {
  const customAuthUrl = `${appConfig.oktaHostedUrl}/oauth2/${appConfig.authorizationServer}/v1`

  oktaAuth = new OktaAuth({
    issuer: `${appConfig.oktaOrgUrl}/oauth2/${appConfig.authorizationServer}`,
    clientId: appConfig.clientId,
    redirectUri: `${window.location.origin}${process.env.VUE_APP_PUBLIC_PATH}/implicit/callback`,
    postLogoutRedirectUri: `${window.location.origin}${process.env.VUE_APP_PUBLIC_PATH}`,
    scopes: [
      'account:read', //common
      'api:iam', //dmiq
      'api:dp', //common
      'api:asset', //dmiq - OK
      'content:read', //common
      'contact:read', //dmiq
      'dealer', //common
      'email', //common
      'equipment:read', //common
      'feedback:read', //machine
      'groups', //common
      'iot:read', //common
      'openid', //common
      'passport:read', //dmiq
      'passport:self', //common
      'product:read', //common
      'profile', //common
      'subscriptions:read', //dmiq
      'api:dms', // partsportal
      'api:partsportal', // partsportal
      'api:opc', // partsportal
      'ngpassport' // enigma
    ],
    pkce: true,
    authorizeUrl: `${customAuthUrl}/authorize`,
    userinfoUrl: `${customAuthUrl}/userinfo`,
    logoutUrl: `${customAuthUrl}/logout`
  })
}
export default oktaAuth
