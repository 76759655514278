import OverlayWrapper from '@/components/ui/OverlayWrapper'
import BIcon from '@/components/ui/BIcon'

const _commonMixin = function (Vue) {
  Vue.config.ignoredElements = ['bicon', 'b-icon']

  Vue.component('overlay-wrapper', OverlayWrapper)
  Vue.component('b-icon', BIcon)

  Vue.directive('click-outside', {
    bind(el, binding, vnode) {
      el.clickOutsideEvent = (event) => {
        const _isOutsideEvent = !(el == event.target || el.contains(event.target))
        const _dPicker = document.getElementsByClassName('mx-datepicker-popup')
        const _isNotFromDatePicker = !(_dPicker.length && _dPicker[0].contains(event.target))

        if (_isOutsideEvent && _isNotFromDatePicker) { vnode.context[binding.expression](event) }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unbind(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    }
  })

  // languageMixin
  Vue.mixin({
    filters: {
      translate(value, labels, key = null) {
        if (labels && labels[key] && typeof labels[key][value] === 'string') return labels[key][value]
        else if (Object.keys(labels).length > 0) return `{ ${key} | ${value} }`
        else return '~~~~~'
      }
    },
    computed: {
      labels() {
        return this.$store.getters['software/getConcepts']
      },
      translatedLabel() {
        return (conceptKey, conceptPath) => this.$options.filters.translate(conceptKey, this.labels, conceptPath)
      }
    }
  })

  return {}
}

export default _commonMixin
