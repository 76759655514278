import Layout from './views/PageLayout'
import TextComponent from './components/magnolia/TextComponent'
import DownloadComponent from './components/magnolia/DownloadComponent'
import AutomatedDownloadComponent from './components/magnolia/AutomatedDownload'

export default {
  componentMappings: {
    // Pages
    'owner-portal:pages/index': Layout,

    // Components
    'owner-portal:components/sectionRTE': TextComponent,
    'owner-portal:components/sectionDownload': DownloadComponent,
    'owner-portal:components/sectionSoftwaresAutomated': AutomatedDownloadComponent
  }
}
