export default {
  'en': {
    'mymachine_InPageNavigation': {
      'locationHistory': 'Location History',
      'serviceHistory': 'Service History',
      'dashboard': 'Dashboard',
      'salesforce': '',
      'faultcodes': 'Fault Codes',
      'salesforceLink': '',
      'troubleshooting': 'Troubleshooting',
      'controllerversions': 'Controller Versions',
      'diagnostic': 'Diagnostic History',
      'fieldmods': 'Field Modifications',
      'warranty': 'Warranty',
      'builtOptions': 'Machine Options',
      'maintenance': 'Maintenance Schedule',
      'safetyInstructions': 'Safety Instructions',
      'manuals': 'Manuals & Safety',
      'schematics': 'Schematics'
    },
    'shared_Footer': {
      'privacyPolicyContent': '',
      'disclaimer': '<div><strong>Bobcat is a Doosan Company.</strong> Doosan is a global leader in construction equipment, power and water solutions, engines, and engineering, proudly serving customers and communities for more than a century.</div>\n\n<div>Bobcat&reg;, the Bobcat logo and the colors of the Bobcat machine are registered trademarks of Bobcat Company in the United States and various other countries.</div>\n\n<div>&copy;[current-year] Bobcat Company. All rights reserved.</div>\n',
      'privacyPolicyTitle': ''
    },
    'mymachine_Abbreviation': {
      'BHL': '',
      'UWM': 'Utility Machine - Toolcat',
      'SSL': 'Skid-Steer Loader',
      'TTC': 'Telescopic Handler',
      'LC': '',
      'ZS': 'Walk-Behind Mower',
      'CTL': 'Compact Track Loader',
      'LEX': 'Excavator',
      'AWS': 'All-Wheel Steer Loader',
      'MEX': 'Excavator',
      'CWL': 'Articulated Loader',
      'ZT': 'Zero Turn Mower',
      'ATT': 'Attachment',
      'CT': 'Compact Tractor',
      'MT': 'Mini-Track Loader',
      'TLS': 'Telescopic Handler',
      'UTV': 'Utility Vehicle',
      'SAL': 'Articulated Loader'
    },
    'mymachine_LocationHistory': {'searchLabel': 'Search'},
    'mymachine_AbbreviationImages': {
      'BHL': '',
      'MT': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat-dev/image/upload/v1587493800/Kags%20Pics%20Dev/Product%20Images/MTL_MT85_svwrou.jpg" style="width:200px" /></p>\n',
      'SAL': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1600258361/Bobcat%20Direct%20Portal/Product%20Images/SAL_L23_tyglk0.png" style="width:200px" /></p>\n',
      'UTV': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat-dev/image/upload/v1587493682/Kags%20Pics%20Dev/Product%20Images/UTV_UV34_ekfyez.jpg" style="width:200px" /></p>\n',
      'AWS': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1587562835/Bobcat%20Direct%20Portal/Product%20Images/AWS_A770_znhw4r.jpg" style="width:200px" /></p>\n',
      'ZT': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1620051664/Bobcat%20Direct%20Portal/Product%20Images/ZT6000_qempta.jpg" style="width:250px" /></p>\n',
      'CTL': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat-dev/image/upload/v1587493131/Kags%20Pics%20Dev/Product%20Images/CTL_T770_t2y4ea.jpg" style="height:200px; width:200px" /></p>\n',
      'UWM': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat-dev/image/upload/v1587493756/Kags%20Pics%20Dev/Product%20Images/UWM_5600_ek8nx2.jpg" style="width:200px" /></p>\n',
      'SSL': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat-dev/image/upload/v1587493552/Kags%20Pics%20Dev/Product%20Images/SSL_S770_we65us.jpg" style="width:200px" /></p>\n',
      'TTC': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat-dev/image/upload/v1587493955/Kags%20Pics%20Dev/Product%20Images/TTC_V519_aumrxv.jpg" style="width:200px" /></p>\n',
      'CT': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat-dev/image/upload/v1587494014/Kags%20Pics%20Dev/Product%20Images/CT_1021_ofn87u.jpg" style="width:200px" /></p>\n',
      'CWL': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1634223840/Bobcat%20Direct%20Portal/Product%20Images/CWL_L65.jpg" style="width:250px" /></p>\n',
      'MEX': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat-dev/image/upload/v1587493867/Kags%20Pics%20Dev/Product%20Images/MEX_E32_jod78o.jpg" style="height:144px; width:200px" /></p>\n',
      'ZS': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1620051664/Bobcat%20Direct%20Portal/Product%20Images/ZS4000_mv7xpw.jpg" style="width:250px" /></p>\n',
      'LEX': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1600258369/Bobcat%20Direct%20Portal/Product%20Images/LEX_E145_v4pfqr.png" style="height:151px; width:200px" /></p>\n',
      'TLS': ''
    },
    'mymachine_ErrorInput': {'enterYourErrorCode': 'Enter error code or symptom'},
    'mymachine_FaultCodes': {
      'startDateGreaterError': 'startDateGreaterError',
      'exportCodesLabel': 'Export Code List',
      'filterEngineHoursLabel': 'Engines Hours',
      'faultCodeLabel': 'Code',
      'noResults': 'No codes to display at this time. Please change filter options or check back later.',
      'descriptionSearchLabel': 'Search code description',
      'engineHoursStartGreaterError': 'engine Hour sStart GreaterError',
      'filterEngineHoursMinLabel': 'Min',
      'title': 'Fault Codes',
      'filterEndDateLabel': 'Incident end',
      'showMore': 'More active codes',
      'engineHoursNegativeError': 'Engine hours too low value',
      'filterEngineHoursMaxLabel': 'Max',
      'codesOccured': 'fault codes occurred in the last 1 hour of runtime.',
      'filterStartDateLabel': 'Start Date Range',
      'searchLabel': 'Search in Troubleshooting',
      'descriptionLabel': 'description',
      'searchLabel0': 'Search',
      'startDateHeaderLabel': 'Incident start',
      'codesCriticalLabel': 'Critical',
      'noResults1': 'No Fault Codes found',
      'codesInformationLabel': 'Information',
      'noCodes': 'There are no active fault codes on this asset.',
      'codeSearchLabel': 'Enter code',
      'defaultError': 'Cannot display codes for this machine',
      'activeCodesTitle': 'Active Fault Codes',
      'viewAll': 'View all',
      'codesWarningLabel': 'Warning',
      'showLess': 'Less active codes'
    },
    'mymachine_WarrantyComponent': {
      'startDateHeader': 'Start date',
      'statusHeader': 'Status',
      'noResults': 'No active or inactive warranties found for this asset at this time.',
      'noWarrantyPlans': 'No active or inactive warranties found for this asset at this time.',
      'hoursHeader': 'Hours',
      'errorNotAuthorized': 'Serial exists in IRW, user is not authorized to see it.',
      'descriptionHeader': 'Plan Description',
      'serialNotFound': 'There was a problem retrieving data for this asset. Please refresh the page or try again later. If the problem continues, contact support.',
      'endDateHeader': 'End date',
      'noWarrantyInclusions': 'There is no warranty inclusions',
      'typeHeader': 'Type',
      'title': 'Warranty'
    },
    'mymachine_WarrantyPolicyNames': {
      'rental': '',
      'used': '',
      'telematics': '',
      'excavators': '',
      'protection_plus': '',
      'blueline': '',
      'upgrade': '',
      'protectionplus': '',
      'powertrain': '',
      'rotary': '',
      'full': '',
      'deutz_engine': '',
      'rotary_telescopic': '',
      'emissions': '',
      'goodwill': '',
      'generator': '',
      'promotional': '',
      'month': '',
      'special_tools': '',
      'telescopic': '',
      'attachment': '',
      'boss_vac': '',
      'year': '',
      'idler_roller': '',
      'no_warranty': '',
      'loaders': '',
      'driveline': '',
      'extended': '',
      'battery': '',
      'standard': '',
      'backhoe_loaders': '',
      'do_not_dissassemble': '',
      'hours': '',
      'transaxle': '',
      'unlimited': '',
      'engine': '',
      'extension': '',
      'mainframe': '',
      'limited': '',
      'russia': '',
      'unlimited_hours': '',
      'day': '',
      'policy': '',
      'key_account': '',
      'months': '',
      'all': '',
      'standard_extension': '',
      'snow_machine': '',
      'maintenance': '',
      'warranty': '',
      'chaincase': '',
      'planned': '',
      'airend': '',
      'powertrain_hydraulics': '',
      'gearbox': '',
      'parts_only': '',
      'drivebelt': '',
      'rigid_telescopic': ''
    },
    'mymachine_BasicTableHeader': {'status': 'Status', 'date': 'Date', 'title': 'Title', 'description': 'Description'},
    'mymachine_FieldMods': {
      'expiresOnLabel': 'Expires on',
      'statusHeader': 'Status',
      'title': 'Field Modifications',
      'errorNotAuthorized': 'Serial exists in IRW, user is not authorized to see it.',
      'noPendingFieldmods': 'There are no pending field mods on this asset',
      'showMore': 'Show more',
      'downloadDetails': 'Click to download service letter',
      'expiresOnHeader': 'Expires on',
      'codeHeader': 'Code',
      'showLess': 'Show less',
      'classHeader': 'Class',
      'descriptionHeader': 'Description'
    },
    'mymachine_NewGuideForm': {
      'issueTypeInvalidFeedback': 'Please select an issue type',
      'emailLabel': '* Email Address',
      'productLabel': '* Machine or Product Impacted',
      'emailPlaceholder': 'FirstLast@Company.com',
      'commentsInvalidFeedback': 'Please give details related to the issue',
      'emailHint': 'The Bobcat My Machine team may contact you with status updates or to clarify your request',
      'faultCodeInvalidFeedback': 'Please enter a fault code or symptom',
      'newGuideFormDescription': '<div>\n<p>Change your search or filter criteria, or, if you can&#39;t find a guide for your issue, you can&nbsp;<a href="#" id="newGuideForm">Report a Missing Guide</a>.</p>\n\n<p>If a Troubleshooting Case is required refer to the <a href="https://dealer.bobcat.com/service/support/troubleshooting">FAQ</a> for how to submit a case.&nbsp;</p>\n</div>\n',
      'issueTypeLabel': '* Issue Type',
      'successModalTitle': 'Report Submitted Successfully',
      'successModalContent': 'Your report of a missing Troubleshooting Guide was submitted successfully. The Bobcat My Machine team may reach out to you if any clarifications are needed.',
      'faultCodeLabel': '* Fault code or symptom',
      'issueTypePlaceholder': 'Select Issue Type (Required)',
      'requestGuideLink': 'Report Missing Guide',
      'productPlaceholder': 'Product Impacted (required)',
      'formTitle': 'Report a Missing Guide',
      'issueTypeValues': 'Fault Code Present\nSymptoms Without Fault Code',
      'detailsLabel': '* Additional details',
      'detailsPlaceholder': 'Additional details related to the request (Required)',
      'submitButton': 'Submit Report',
      'faultCodePlaceholder': 'Fault code or symptom (required)',
      'formDescription': '<p>Use this form ONLY if you can&#39;t find a guide that matches the issue your troubleshooting. If you need further assistance on an issue, visit the&nbsp;<a href="https://dealer.bobcat.com/service/support/troubleshooting">FAQs</a>&nbsp;for information on how to open a case.&nbsp;</p>\n\n<p>&nbsp;</p>\n',
      'productInvalidFeedback': 'Please enter a machine or product.'
    },
    'mymachine_MachineLibraryComponent': {
      'manuals': 'Manuals',
      'safetyInstructionsNotice': 'Please refer to the Service Manual or Operation & Maintenance Manual for this product’s safety instructions.',
      'serviceManuals': 'Service Manuals',
      'error': 'Error',
      'operationManualTitle': 'Operation & Maintenance Manual',
      'noServiceManual': 'No Service Manual is available for this asset at this time.',
      'noOpManual': 'No Operation and Maintenance Manual is available for this asset at this time.',
      'serviceManualTitle': 'Service Manual'
    },
    'shared_SoftwareComponent': {'errorLoadingPage': 'Error loading page'},
    'mymachine_MachinePasswordImages': {
      '1': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1628595583/Bobcat%20Dealer%20Portal/Service/Machine%20Password/1_x0pcup.png" style="width:200px" /></p>\n',
      '2': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1628595595/Bobcat%20Dealer%20Portal/Service/Machine%20Password/2_jjhqgo.png" style="width:200px" /></p>\n',
      '3': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1628595595/Bobcat%20Dealer%20Portal/Service/Machine%20Password/3_oljl40.png" style="width:200px" /></p>\n',
      '4': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1628595596/Bobcat%20Dealer%20Portal/Service/Machine%20Password/4_azg9xs.png" style="width:200px" /></p>\n',
      '5': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1628595598/Bobcat%20Dealer%20Portal/Service/Machine%20Password/5_qxybm5.png" style="width:200px" /></p>\n'
    },
    'mymachine_SafetyInstructionsComponent': {
      'title': 'Safety Instructions',
      'noSafetyDoc': 'Please refer to the Service Manual (if available) or the Operation and Maintenance Manual for safety instructions for this product.'
    },
    'mymachine_MachineDetails': {
      'noDataError': 'Error, please refresh',
      'diagmasterLabel': 'Kubota Diagmaster',
      'viewMapLink': 'View the map',
      'dMS3Label': 'Doosan Monitoring Software (DMS 3)',
      'versaAnalyzerLabel': 'VersaAnalyzer',
      'machineIQ': 'Machine IQ',
      'machineImage': 'Machine Image',
      'serviceAnalyzerUrl': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/service-analyzer',
      'cT55Url': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/bobcat-tractor',
      'latestVersionsOfSoftware': 'Available Software - Latest Version',
      'v2engineAnalyzerUrl': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/bobcat-engine-analyzer-software',
      'dMS3Url': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/dms-doosan-monitoring-software',
      'noSerialNumber': 'No serial number',
      'smartAssistLabel': 'Yanmar SMARTASSIST',
      'fT4ScanBobcatUrl': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/bobcat-tractor',
      'machineImageAlt': 'Product Image',
      'engineAnalyzerLabel': 'Bobcat Engine Analyzer',
      'noSoftwareError': 'Software not available',
      'engineAnalyzerUrl': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/bobcat-engine-analyzer-software',
      'serviceAnalyzerLabel': 'Service Analyzer',
      'enterYourErrorCode': 'Enter error code or symptom',
      'perkinsEDTUrl': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/perkins-edt-electronic-diagnostic-tool',
      'unauthorizedError': 'Not authorized',
      'smartAssistUrl': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/yanmar-sa-d-smartassist-direct',
      'notInDealership': 'Not Assigned to Your Dealership',
      'iNeedToTroubleshootErrorCode': 'Search Guided Troubleshooting',
      'operatingHoursLabel': 'Operating Hours',
      'fuelLevelLabel': 'Fuel Level',
      'noData': 'Not Equipped',
      'cT1025Label': 'CT1025 ECU',
      'digitalWrenchUrl': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/bobcat-digital-wrench',
      'locationLabel': 'Location',
      'defaultError': 'Error',
      'v2engineAnalyzerLabel': 'V2 Bobcat Engine Analyzer',
      'versaAnalyzerUrl': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/versa-analyzer',
      'fT4ScanLabel': 'Bobcat Tractor ECU (FT4Scan)',
      'privacyPolicyShort': 'Location data restricted by privacy policy',
      'errorNotConnected': 'No Data',
      'diagmasterUrl': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/kubota-diagmaster',
      'digitalWrenchLabel': 'Bobcat Digital Wrench',
      'cT55Label': 'APECS 4800',
      'cT1025Url': 'https://www.bobcatdealernet.com/service-and-warranty/service-software-and-tools/bobcat-tractor',
      'defLevelLabel': 'DEF Level',
      'perkinsEDTLabel': 'Perkins EDT'
    },
    'servicelibrary_ServiceLibraryFacets': {
      'Backhoe-Loader': 'Backhoe Loader',
      'Field-Modifications': 'Field Modifications',
      'Articulate-Loader': 'Articulate Loader',
      'Compact-Tractor': 'Compact Tractor',
      'Ops-Handbook': 'Ops Handbook',
      'Light-Construction': 'Light Construction ',
      'Service-Letters': 'Service Letters',
      'Attachment': 'Attachment',
      'Loader-All-Wheel-Steer': 'Loader, All Wheel Steer',
      'Loader-Compact-Tracked': 'Loader, Compact Tracked',
      'Instruction': 'Instruction ',
      'Excavator': 'Excavator',
      'Loader-Skid-Steer': 'Loader, Skid Steer ',
      'User-Guide': 'User Guide',
      'Compaction': 'Compaction',
      'Loader': 'Loader',
      'Light-Compaction': 'Light Compaction',
      'General': 'General',
      'Safety-Notices': 'Safety Notices',
      'Operation-Maintenance-Manual': 'Operation & Maintenance Manual',
      'Loader-Mini-Track': 'Loader Mini Track',
      'Service-Manual': 'Service Manual'
    },
    'mymachine_DiagnosticHistory': {
      'external': 'External',
      'diagnosticSessionResumed': 'Session resumed by',
      'exportToPDF': 'Export to PDF',
      'noDiagnosticHistoryShort': 'No history records found for this asset.',
      'diagnosticSessionPaused': 'Paused',
      'internalShort': 'I',
      'serviceTitle': 'Service History',
      'openWarrantyDetails': 'View in IRW',
      'internal': 'Internal',
      'diagnosticSessionFinished': 'Finished New Solution',
      'viewInIRW': 'View in IRW',
      'vehicleSessionSummary': 'Vehicle Session Summary',
      'diagnosticSessionStarted': 'Session started by',
      'downloadDetails': 'Download Details',
      'partsReplaced': 'Parts Replaced',
      'allHistory': 'Diagnostic History',
      'resultsLabel': 'results',
      'diagnosticSessionHistory': 'Diagnostic Session History',
      'warrantyClaimSummary': 'Warranty Claims Summary',
      'title': 'Diagnostic History',
      'noResultsFiltered': 'No history to display at this time. Please change filter options or check back later.',
      'externalShort': 'E',
      'hoursShort': 'hours',
      'origin': 'Origin',
      'resumingNotAllowed': 'Resuming not allowed',
      'showAll': 'Show all',
      'noDiagnosticHistory': 'No history to display at this time.  Please change filter options or check back later.',
      'warrantyLink': 'https://warranty.doosanpassport.com/viewQuickClaimSearchDetail.action?context=ClaimSearches&claimNumber=[id]',
      'resumeSession': 'Resume Session',
      'viewAll': 'View all'
    },
    'servicelibrary_ServiceLibraryComponent': {
      'searchLabel': 'Search Library',
      'noResults': 'No Results.',
      'moreThanResultsLabel': 'More than [total] results',
      'showMore': 'Show More',
      'downloadError': 'Error:  Item not available to download',
      'welcome': 'Welcome to the Bobcat Service Library, home of the most up-to-date Bobcat service information.',
      'resultsLabel': 'results',
      'refineSearch': 'Refine your Search',
      'componentTitle': 'Service Library',
      'searchInputPlaceholder': 'Search Input'
    },
    'shared_CommonDateTime': {
      'toLabel': 'To',
      'todayLabel': 'Today',
      'beforeLabel': 'Before',
      'expiresOnLabel': 'Expires on',
      'fromLabel': 'From'
    },
    'mymachine_MachinePassword': {
      'retrievePassword': 'Retrieve Password',
      'password': 'Password:',
      'setPanelType': 'Set Panel Type',
      'subtitlePasswordPanel': 'Select the panel type that matches your machine to display the master password',
      'titlePasswordPanel': 'Select Machine Panel Type'
    },
    'software_SoftwareComponent': {
      'errorLoadingPage': '',
      'mockedData': 'Notice: These are mocked data for rendering purposes only'},
    'shared_CommonErrors': {
      'noPagesFound': 'No pages found',
      'equipmentAPIError': 'We cannot retrieve the data for Warranty Claims for now. Please try again later. ',
      'searchingError': 'Error',
      'downloadError': 'Document not available, please use service library.  If the issue persists, contact support.',
      'productAPIError': 'We cannot retrieve data for Diagnostic history for now. Please try again later. ',
      'productAPIandEquipmentAPIError': 'There was a problem retrieving data for this asset. Please refresh the page or try again later. If the problem continues, contact support.',
      'errorNotInDealership': 'This asset is not assigned to your dealership(s) for service.',
      'noResults': 'No results.',
      'noSerialFound': 'No product information can be found for this serial number.',
      'errorCode': 'Error Code:',
      'regionsError': 'You don\'t have any region assigned',
      'unauthorizedError': 'There was a problem retrieving data for this asset.  Please refresh the page or try again later.  If the problem continues, contact support.',
      'pageAccessError': 'You cannot access this page',
      'sessionError': 'There was a problem retrieving data for this asset.  Please refresh the page or try again later. If the problem continues, contact support.',
      'startDateGreaterError': 'star tDate Greater Error',
      'featureUnauthorizedNoRedirect': 'You are not authorized to access this application.',
      'unauthorizedSection': 'User is not authorized to access this section.',
      'oktaUnauthorized': 'An error has occured.',
      'defaultError': 'There was a problem retrieving data for this asset.  Please refresh the page or try again later.  If the problem continues, contact support.',
      'internalSessionAccessError': 'Error finding the session history, please check the serial number and session ID.',
      'alaoRoleAccessError': 'You cannot access this feature (copy to confirm)',
      'errorNotConnected': 'Not Equipped.  This asset does not have an active telematics device installed.',
      'featureUnauthorized': 'User not authorized to use this feature.  Redirecting...',
      'historySessionIDError': 'Error finding the session history, please check the serial number and session ID',
      'noRegionsError': 'You don\'t have any region assigned. ',
      'emeaRoleAccessError': 'You cannot access this paid feature (copy to confirm)',
      'invalidQueryRegion': 'You cannot access this region',
      'notSupportedByTheTroubleshooting': 'This asset is not supported by the troubleshooting system at this time. Please use the non-serialized asset option to create a case.',
      'emeaUnauthorized': 'User is not authorized to access this application. ',
      'usbFormNotAvailable': 'USB form is not available.'
    },
    'shared_LanguagesISO': {
      'frCA': 'Français (CA)',
      'it': '',
      'de': 'Deutsch (DE)',
      'skSK': '',
      'slSI': '',
      'enGB': 'English (GB)',
      'ptPT': 'Português (PT)',
      'pt': '',
      'nlNL': '',
      'srRS': '',
      'ru': '',
      'daDK': '',
      'elGR': '',
      'itIT': '',
      'lvLV': '',
      'isIS': '',
      'csCZ': '',
      'en': 'English (US)',
      'plPL': '',
      'huHU': '',
      'fr': 'Français (CA)',
      'trTR': '',
      'ruRU': '',
      'roRO': '',
      'svSE': '',
      'ar': '',
      'ko': '',
      'esAR': 'Español (AR)',
      'noNO': '',
      'esES': 'Español (ES)',
      'zh': '',
      'etEE': '',
      'enUS': 'English (US)',
      'es': 'Español (ES)',
      'fiFI': '',
      'frFR': 'Français (FR)',
      'bgBG': '',
      'deDE': 'Deutsch (DE)',
      'ptBR': 'Português (BR)',
      'ltLT': '',
      'hrHR': ''
    },
    'shared_HeaderMenu': {
      'logoutLabel': 'Logout',
      'brandingName': 'Service',
      'softwareTitle': 'Software',
      'myMachinePath': '/service/mymachine',
      'softwarePath': '/service/software',
      'softwareIcon': 'bobcaticon-software',
      'supportIcon': 'question-circle',
      'supportPath': '/service/support',
      'myMachineTitle': 'My Machine',
      'serviceLibraryIcon': 'book',
      'myMachineIcon': 'bobcaticon-asset',
      'serviceLibraryTitle': 'Service Library',
      'supportTitle': 'Support',
      'serviceLibraryPath': '/service/library'
    },
    'shared_Header': {
      'logoutLabel': 'Logout',
      'brandingName': 'Service',
      'softwareTitle': 'Software',
      'myMachinePath': '/service/mymachine',
      'softwarePath': '/service/software',
      'softwareIcon': 'bobcaticon-software',
      'supportIcon': 'question-circle',
      'supportPath': '/service/support',
      'myMachineTitle': 'My Machine',
      'serviceLibraryIcon': 'book',
      'myMachineIcon': 'bobcaticon-asset',
      'serviceLibraryTitle': 'Service Library',
      'supportTitle': 'Support',
      'serviceLibraryPath': '/service/library'
    },
    'shared_Regions': {
      'EMEA': 'EMEA - Europe, Middle East, Africa',
      'currentRegion': 'Current region:',
      'NA': 'NA - North America',
      'ALAO': 'ALAO - Latin America, Asia, Oceania',
      'selectRegion': 'Select your region',
      'setRegion': 'Set the region',
      'emptyRegions': 'Empty region'
    },
    'shared_LocalesKebabCase': {
      'zh': '',
      'de': 'de-DE',
      'nl': 'nl-NL',
      'ko': '',
      'pt': '',
      'es': 'es-ES',
      'ru': 'ru-RU',
      'en': 'en-US',
      'fr': 'fr-FR',
      'it': 'it-IT'
    },
    'mymachine_NoSerialForm': {
      'formDescription': 'You can still start a Bobcat Service Troubleshooting case for assets that don’t have serial numbers. We’ll need to collect some information before we continue:',
      'productHoursPlaceholder': 'Product Hours (required)',
      'productTypeValues': 'Attachments\nBackhoe Loader\nCompact Tractor\nExcavator\nLoader\nMini Track Loader\nSmall Articulated Loader\nTelehandler\nUtility Vehicles\nWork Machine\nZero Turn Riding Mower\nZero Turn Stand-On Mower',
      'caseSubTypeValues': 'Equipment Failure',
      'productTypePlaceholder': 'Select Product Type (Required)',
      'caseSubTypeLabel': '* Case Sub-Type',
      'formTitle': 'Start a Bobcat Service Troubleshooting Case',
      'systemLabel': '* System',
      'systemPlaceholder': 'Select System (Required)',
      'productHoursHint': 'If product hours are unknown, use your best estimate.',
      'caseSubTypeInvalidFeedback': 'Please select Sub-Type',
      'productHoursLabel': '* Product Hours',
      'acceptanceLabel': 'I confirm that this asset does not have a serial number as recognized by My Machine',
      'caseSubTypePlaceholder': 'Select Sub-Type (Required)',
      'submitButton': 'Create A Case',
      'productHoursInvalidFeedback': 'Please enter Product Hours',
      'productTypeLabel': '* Product Type',
      'acceptanceInvalidFeedback': 'Please confirm this asset’s serial status',
      'productTypeWithoutTSP': 'Attachments\nLoader',
      'systemInvalidFeedback': 'Please select System',
      'systemValues': 'Electrical\nHydraulic\nPublications\nRunning Gear\nSoftware\nStructural\nWater (kits)',
      'useTSMessage': '<p>This product&rsquo;s serial numbers are compatible with My Machine. Please start by opening a Guided Troubleshooting session for this asset in My Machine using its serial number. If you cannot find this asset&rsquo;s serial number, you can learn more about how to locate it <u><strong><a href="https://preprod-doosan-nao.cs35.force.com/dealernet/articles/Hidden-Machine-Serial-Number" target="_blank">by clicking here</a>.</strong></u></p>\n',
      'productTypeInvalidFeedback': 'Please select Product Type'
    },
    'mymachine_ServiceHistoryWarrantyTypes': {'MACHINE': 'Machine', 'CAMPAIGN': 'Campaign'},
    'shared_Languages': {
      'it': '',
      'bg': '',
      'is': '',
      'fi': '',
      'ru': '',
      'es': 'Español',
      'pt': 'Português',
      'el': '',
      'de': '',
      'no': '',
      'hu': '',
      'sl': '',
      'fr': 'Français',
      'nl': '',
      'tr': '',
      'sk': '',
      'hr': '',
      'lv': '',
      'ar': '',
      'ko': '',
      'sv': '',
      'da': '',
      'cs': '',
      'ro': '',
      'et': '',
      'pl': '',
      'zh': '',
      'lt': '',
      'en': 'English',
      'sr': ''
    },
    'servicelibrary_USBFormComponent': {
      'formTitle': 'Request USB Subscription Changes',
      'requestTypeInvalidFeedback': 'Please make a selection',
      'shippingAddressLabel': 'Shipping Address:',
      'submitButton': 'Submit Request',
      'ARNumber': 'AR Number',
      'shippingAddressUpdateContent': 'To update Dealer Mailing Information, please contact the Dealer Call Center.',
      'acceptanceLabel': 'I Accept the USB Service Library',
      'billToARPlaceholder': 'Enter Bill To AR # (Optional)',
      'clearButton': 'Clear Form',
      'requestorEmailPlaceholder': 'Firstname@domain.com',
      'currentYearSubscriptions': 'Current Year Subscriptions',
      'noDealersFound': 'We were unable to find any Dealer accounts associated with your account. Please contact support and check again later.',
      'successModalContent': 'Your USB Service Library subscription change request was submitted successfully. Your subscriber list will be updated when your request is processed, which may take up to 3 business days.',
      'commentsPlaceholder': 'Enter any additional comments (Optional)',
      'subscriptionsInvalidFeedback': 'Maximum subscription order size is 10. To order more, contact Service Library support at BobcatServiceLibraryFeedback@doosan.com',
      'nextYearSubscriptions': 'Next Year Subscriptions',
      'subscriptionsPriceContent': '<div>Annual Price Per Subscription:</div>\n\n<div>$475.00 USD</div>\n',
      'requestTypeCancel': 'Cancel All Subscriptions',
      'termsAndConditionsTitle': 'USB Service Library Terms & Conditions',
      'requestTypeChange': 'Change Current Subscriptions',
      'troubleshootingSupport': 'FAQs',
      'requestChangesButton': 'Request Changes',
      'dealershipName': 'Dealership Name',
      'requestTypeLabel': '* Requested Change:',
      'requestorNameLabel': '* Requestor Name',
      'GMARInvalidFeedback': 'The USB Service Library subscription service is not available for Grounds Maintenance dealers.',
      'backButton': 'Back to Service Library',
      'requestTypeAdd': 'Add New Subscriptions',
      'billToARLabel': 'Bill To AR # (If different than ship to)',
      'requestorEmailLabel': '* Requestor Email',
      'successModalTitle': 'Request Submitted Successfully',
      'shipToARInvalidFeedback': 'Please enter a valid AR number associated with your account',
      'shipToARPlaceholder': 'Enter Ship To AR # (Required)',
      'componentTitle': 'Manage USB Drive Subscriptions',
      'termsAndConditionsLabel': 'Terms & Conditions',
      'requestorNameInvalidFeedback': 'Please enter a first and last name',
      'subscriberListTitle': 'Subscriber List',
      'shipToARLabel': '* Ship To AR #',
      'featureUnauthorized': 'USB service library is not available for the logged in user.',
      'shippingAddressInvalidFeedback': 'Please Enter Your Ship To AR to see your shipping address.',
      'commentsLabel': 'Comments',
      'troubleshootingLink': '/service/support/service-library-usb',
      'lastUpdated': 'Last Updated',
      'requestorNamePlaceholder': 'Firstname Lastname',
      'acceptanceInvalidFeedback': 'Please accept the Terms and Conditions before submitting your request',
      'termsAndConditionsContent': '<div>The USB Service Library is an optional paid subscription service available to Bobcat Dealers who want access to the information available through&nbsp;the online Service Library without requiring an internet connection.&nbsp; A typical example would be for use on service trucks.<br />\n<br />\nEach subscription includes a 64GB USB drive mailed to your dealer&#39;s official mailing address* on a quarterly basis, typically in May, August, November and February.&nbsp; Each drive contains all information available through the online Service Library at the time of production.&nbsp; The annual cost is $475.00 USD per subscription and is renewed automatically each year. Use the Subscription Status Change form below to subscribe or unsubscribe from the USB mailing.&nbsp; Dealers must subscribe or unsubscribe by April 15 to ensure that you receive all four drives in the subscription year that begins May 1.&nbsp; Your dealership can subscribe or unsubscribe at any time during the year but will be charged the FULL subscription amount and will not receive any drives previously sent out during the current subscription year.&nbsp; Dealers who unsubscribe during the subscription year will continue to receive USBs through the end of their subscription.<br />\n<br />\nThis subscription service is available only to current Bobcat Full Line and Construction Equipment (CE) dealers, and is intended for dealer use.&nbsp; Each drive will expire May 31 of the following subscription year (i.e.&nbsp;drives for the 2021 subscription year will expire on May 31, 2022).<br />\n<br />\n<br />\n* To update dealer mailing information, please contact the Dealer Call Center.</div>\n',
      'subscriptionsLabel': '* Total Desired Subscriptions',
      'dealerPOPlaceholder': 'Enter Dealer PO # (Optional)',
      'dealerPOLabel': 'Dealer Purchase Order #'
    },
    'mymachine_ControllersList': {
      'esn': 'ESN',
      'aux_mx_secondary_version': 'Auxiliary/MX Secondary',
      'drive_version': 'Drive',
      'workgroup_throttle_version': 'Workgroup/Throttle',
      'acd_version': 'ACD',
      'deluxe_panel_version': 'Deluxe Panel',
      'gateway_version': 'Gateway'
    },
    'support_SidebarComponent': {
      'headingDescription': '<p>Have questions or need support?<br />\nBrowse our FAQs.<br />\n<br />\n&nbsp;</p>\n',
      'goToLabel': 'Go to ...',
      'headingLabel': 'Support'
    },
    'mymachine_WarrantyDetails': {
      'concern': 'Concern',
      'type': 'Claim Type',
      'cause': 'Cause',
      'faultLocation': 'Fault Location',
      'dateOfClaim': 'Date of Claim',
      'casualPart': 'Casual Part',
      'failureDate': 'Failure Date',
      'status': 'Status',
      'correction': 'Correction',
      'comments': 'Comments',
      'claimNumber': 'Claim Number',
      'machineHours': 'Machine Hours',
      'repairDate': 'Repair Date'
    },
    'mymachine_ServiceHistoryStates': {
      'Finished-New-Solution': 'Completed',
      'ACCEPTED_AND_CLOSED': 'Accepted & Closed',
      'Finished-No-Trouble-Found': 'Completed'
    },
    'mymachine_SchematicsTableComponent': {
      'noElecSchematics': 'Electric Schematics are not available for this asset at this time.',
      'electricSchematics': 'Electric',
      'hydraulicSchematics': 'Hydraulic',
      'noHydSchematics': 'Hydraulic Schematics are not available for this asset at this time.',
      'downloadDetails': 'Click to download schematic',
      'schematics': 'Schematics'
    },
    'shared_CommonFunctional': {
      'gotoPageLabel': 'Go to page',
      'toLabel': 'To',
      'close': 'Close',
      'applyButton': 'Apply',
      'IDLabel': 'ID',
      'categoryHeader': 'Category',
      'saveFilters': 'Save Filters',
      'descriptionHeader': 'Description',
      'originLabel': 'Origin',
      'selectFiltersLabel': 'Select Filter',
      'emptySearchButton': 'Search',
      'searchButton': 'Search',
      'selectedOrganization': 'Selected organization:',
      'cancelAndLogout': 'Cancel and logout',
      'sessionStarting': 'Start A New Guided Troubleshooting Session',
      'selectOrganizationText': 'Please select an organization to start the session.',
      'severityLabel': 'Severity',
      'redirectPath': '/service/mymachine',
      'titleLabel': 'Title',
      'dateLabel': 'Date',
      'showLabel': 'Show',
      'loadMore': 'Load more',
      'descendingLabel': 'descending',
      'sortByLabel': 'Sort by ',
      'selectOrganization': 'Select an organization:',
      'resultsLabel': 'Results',
      'filterBy': 'Filter by ',
      'closeButton': 'Close',
      'redirectAfterSec': '10',
      'typeLabel': 'Type',
      'cancel': 'Cancel',
      'descriptionLabel': 'Description',
      'languageTitle': 'Language',
      'clearAll': 'Clear all',
      'showLess': 'Show less',
      'clearLabel': 'Clear',
      'viewAll': 'View All',
      'resetFilters': 'Reset filters',
      'languageHeader': 'Language',
      'showMore': 'Show more',
      'titleHeader': 'Title',
      'ascendingLabel': 'ascending',
      'clearFilters': 'Clear filters',
      'statusLabel': 'Status',
      'resetFormButton': 'Reset Form',
      'hrsLabel': 'Hrs',
      'sessionResuming': 'Resume A Guided Troubleshooting Session',
      'clearFiltersAll': 'Clear all',
      'refNoHeader': 'Ref Number',
      'downloadDetails': 'Download file',
      'filterSaved': 'Filter saved'
    },
    'mymachine_LocationHistoryComponent': {
      'locationPrivacyPolicy': 'Current location and history for this machine are not available per our privacy policy.',
      'clearFilters': 'Reset Filters',
      'toLabel': 'to',
      'googleMapsAPIKey': 'AIzaSyDXesBhPiCK5N4eMMXEl4PY8DOnU2bxBZw',
      'title': 'Location History',
      'startDateLabel': 'Start Date',
      'dateTimeLabel': 'Date & Time',
      'fromLabel': 'from',
      'noLocationsFound': 'No Location History to display at this time. Please change date filter options or check back later.',
      'endDateLabel': 'End Date',
      'locationLabel': 'Location'
    },
    'mymachine_MaintenanceComponent': {
      'kitHeader': 'Kit Part Number',
      'noMaintInterval': 'Maintenance internal information is not available for this asset at this time.',
      'noMaintDoc': 'Maintenance Instructions are not available for this asset at this time.',
      'title': 'Maintenance Schedule',
      'notice': 'Note: This asset’s standard maintenance schedule is available below. Some assets may have dealer-configured custom maintenance schedules.',
      'machineNotFound': 'Machine not found',
      'intervalHeader': 'Interval',
      'maintenanceInfoLabel': 'Service Schedule',
      'maintenanceDownloadTitle': 'Download Maintenance Instructions'
    },
    'support_SupportComponent': {'noQuestions': 'No questions yet.'},
    'mymachine_ServiceHistory': {
      'external': 'External',
      'diagnosticSessionResumed': 'Session resumed by',
      'exportToPDF': 'Export to PDF',
      'noDiagnosticHistoryShort': 'No history records found for this asset.',
      'diagnosticSessionPaused': 'Paused',
      'internalShort': 'I',
      'serviceTitle': 'Service History',
      'openWarrantyDetails': 'View in IRW',
      'internal': 'Internal',
      'diagnosticSessionFinished': 'Finished New Solution',
      'viewInIRW': 'View in IRW',
      'vehicleSessionSummary': 'Vehicle Session Summary',
      'resumeSessionInEnglish': 'Resume session in English',
      'diagnosticSessionStarted': 'Session started by',
      'downloadError': 'Error: Item is not available to download',
      'downloadDetails': 'Download Details',
      'partsReplaced': 'Parts Replaced',
      'allHistory': 'Diagnostic History',
      'resultsLabel': 'results',
      'diagnosticSessionHistory': 'Diagnostic Session History',
      'warrantyClaimSummary': 'Warranty Claims Summary',
      'title': 'Diagnostic History',
      'noResultsFiltered': 'No history to display at this time. Please change filter options or check back later.',
      'externalShort': 'E',
      'hoursShort': 'hours',
      'origin': 'Origin',
      'resumingNotAllowed': 'Resuming not allowed',
      'showAll': 'Show all',
      'noDiagnosticHistory': 'No history to display at this time.  Please change filter options or check back later.',
      'warrantyLink': 'https://warranty.doosanpassport.com/viewQuickClaimSearchDetail.action?context=ClaimSearches&claimNumber=[id]',
      'resumeSession': 'Resume Session',
      'viewAll': 'View all'
    },
    'software_SidebarComponent': {'headingDescription': '', 'headingLabel': 'Software'},
    'mymachine_ControllerVersions': {
      'title': 'Controller Versions',
      'version': 'Version',
      'defaultError': 'Cannot display controller versions for this machine',
      'errorConnection': 'This asset is either not connected or not eligible to report controller versions. Please check your machine’s connection or try again later. ',
      'none': '(none)',
      'controller': 'Controller',
      'noResults': 'No results.'
    },
    'mymachine_ServiceHistoryTypes': {
      'MACHINE': 'Warranty Claim',
      'ATTACHMENT': 'Warranty Claim',
      'PARTS': 'Warranty Claim',
      'CAMPAIGN': 'Warranty Claim',
      'Guided-session': 'Guided Session'
    },
    'shared_Locales': {
      'de': 'de_DE',
      'zh': '',
      'it': 'it_IT',
      'en': 'en_US',
      'es': 'es_ES',
      'ko': '',
      'pt': '',
      'fr': 'fr_FR',
      'ru': 'ru_RU'
    },
    'mymachine_MaintenanceScheduleIntervals': {'regular': '', 'first': ''},
    'shared_RegionNames': {
      'EMEA': 'EMEA - Europe, Middle East, Africa',
      'currentRegion': 'Current region:',
      'NA': 'NA - North America',
      'ALAO': 'ALAO - Latin America, Asia, Oceania',
      'selectRegion': 'Select your region',
      'setRegion': 'Set the region',
      'emptyRegions': 'Empty region'
    },
    'mymachine_TroubleshootingProcedures': {
      'noProceduresFound': 'No procedures found.',
      'title': 'Troubleshooting',
      'sessionLanguageNotAvailable': 'Starting and resuming session is not available in your language. It\'s available only in English.',
      'rank': 'Rank',
      'action': 'Action',
      'enterYourErrorCode': 'Enter symptom or fault code',
      'startSession': 'Start Session',
      'procedureName': 'Procedure name',
      'troubleshootingSupport': 'FAQs',
      'noTroubleshootingResult': 'No troubleshooting procedures found for the query',
      'startSessionEnglish': 'Start in English',
      'troubleshootingSupportLink': '/service/support/troubleshooting',
      'sessionStartError': 'Session start failed',
      'description': 'Description'
    },
    'software_ImpersonatorModal': {
      'availableTypes': 'Available Types: ',
      'description': 'Select contract codes available to user to simulate page visibility',
      'selectedTypes': 'Show pages for dealers with contract types: (leave empty to see all pages)',
      'modalTitle': 'Dealer\'s contract codes:',
      'changeCodes': 'Change user\'s contract codes and types',
      'availableCodes': 'Available Codes: ',
      'selectedCodes': 'Show pages for dealers with contract codes: (leave empty to see all pages)'
    },
    'mymachine_OptionsComponent': {
      'descriptionLabel': 'Description',
      'title': 'Options',
      'itemNumberLabel': 'Item number',
      'noOptions': 'No machine options found.'
    },
    'mymachine_Software': {
      'noDataError': 'Error, please refresh',
      'diagmasterLabel': 'Kubota Diagmaster',
      'viewMapLink': 'View the map',
      'dMS3Label': 'Doosan Monitoring Software (DMS 3)',
      'versaAnalyzerLabel': 'VersaAnalyzer',
      'machineIQ': 'Machine IQ',
      'versaAnalyzerUrl': 'https://dealer.bobcat.com/service/software/versa-analyzer',
      'machineImage': 'Machine Image',
      'diagmasterUrl': 'https://dealer.bobcat.com/service/software/kubota--diagmaster',
      'cT55Url': 'https://dealer.bobcat.com/service/software/tractor',
      'deutzSerdiaLabel': '',
      'latestVersionsOfSoftware': 'Available Software - Latest Version',
      'noSerialNumber': 'No serial number',
      'smartAssistLabel': 'Yanmar SMARTASSIST',
      'smartAssistUrl': 'https://dealer.bobcat.com/service/software/yanmar-smartassist-direct',
      'machineImageAlt': 'Product Image',
      'perkinsEDTUrl': 'https://dealer.bobcat.com/service/software/perkins-edt',
      'digitalWrenchUrl': 'https://dealer.bobcat.com/service/software/digital-wrench',
      'deutzSerdiaUrl': '',
      'engineAnalyzerLabel': 'Bobcat Engine Analyzer',
      'noSoftwareError': 'Software not available',
      'serviceAnalyzerLabel': 'Service Analyzer',
      'enterYourErrorCode': 'Enter error code or symptom',
      'unauthorizedError': 'Not authorized',
      'iNeedToTroubleshootErrorCode': 'Search Guided Troubleshooting',
      'operatingHoursLabel': 'Operating Hours',
      'v2engineAnalyzerUrl': 'https://dealer.bobcat.com/service/software/bobcat-engine-analyzer',
      'engineAnalyzerUrl': 'https://dealer.bobcat.com/service/software/bobcat-engine-analyzer',
      'fT4ScanBobcatUrl': 'https://dealer.bobcat.com/service/software/tractor',
      'fuelLevelLabel': 'Fuel Level',
      'cT1025Label': 'CT1025 ECU',
      'locationLabel': 'Location',
      'serviceAnalyzerUrl': 'https://dealer.bobcat.com/service/software/service-analyzer',
      'dMS3Url': 'https://dealer.bobcat.com/service/software/dms-doosan-monitoring-software',
      'defaultError': 'Error',
      'v2engineAnalyzerLabel': 'V2 Bobcat Engine Analyzer',
      'fT4ScanLabel': 'Bobcat Tractor ECU (FT4Scan)',
      'cT1025Url': 'https://dealer.bobcat.com/service/software/tractor',
      'errorNotConnected': 'No Data',
      'digitalWrenchLabel': 'Bobcat Digital Wrench',
      'cT55Label': 'APECS 4800',
      'defLevelLabel': 'DEF Level',
      'perkinsEDTLabel': 'Perkins EDT'
    },
    'servicelibrary_ServiceLibraryFilters': {
      'instruction_filter': 'Instruction',
      'language_filter': 'Language',
      'bulletin_filter': 'Bulletin',
      'locale_filter': 'Languages',
      'product_filter': 'Product',
      'manual_filter': 'Manual'
    },
    'mymachine_PrefixImages': {
      'B4KL': '',
      'B4SC': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442254/Bobcat%20Dealer%20Portal/Service/Product%20Images/S64-B4SC_diptb9.jpg" style="width:200px" /></p>\n',
      'B4SD': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442254/Bobcat%20Dealer%20Portal/Service/Product%20Images/T64-B4SD_kgjzzo.jpg" style="width:200px" /></p>\n',
      'B4CD': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442254/Bobcat%20Dealer%20Portal/Service/Product%20Images/S76-B4CD_B4ZY_at42de.jpg" style="width:200px" /></p>\n',
      'B4SB': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442511/Bobcat%20Dealer%20Portal/Service/Product%20Images/T66-B4SB_B51V_cnyymh.jpg" style="width:200px" /></p>\n',
      'B55N': '',
      'B4PF': '',
      'B4SA': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442253/Bobcat%20Dealer%20Portal/Service/Product%20Images/S66-B4SA_B51U_nwenmz.jpg" style="width:200px" /></p>\n',
      'A4BP': '',
      'B51V': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442511/Bobcat%20Dealer%20Portal/Service/Product%20Images/T66-B4SB_B51V_cnyymh.jpg" style="width:200px" /></p>\n',
      'B4BD': '',
      'B4CE': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442254/Bobcat%20Dealer%20Portal/Service/Product%20Images/T76-B4CE_B4ZZ_q1x8tc.jpg" style="width:200px" /></p>\n',
      'B4SE': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442253/Bobcat%20Dealer%20Portal/Service/Product%20Images/S62-B4SE_avzse5.jpg" style="width:200px" /></p>\n',
      'A33P': '',
      'B4PC': '',
      'B51U': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442253/Bobcat%20Dealer%20Portal/Service/Product%20Images/S66-B4SA_B51U_nwenmz.jpg" style="width:200px" /></p>\n',
      'B4SF': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442254/Bobcat%20Dealer%20Portal/Service/Product%20Images/T62-B4SF_mvaykp.jpg" style="width:200px" /></p>\n',
      'B4ZZ': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442254/Bobcat%20Dealer%20Portal/Service/Product%20Images/T76-B4CE_B4ZZ_q1x8tc.jpg" style="width:200px" /></p>\n',
      'B4ZY': '<p><img alt="" src="https://res.cloudinary.com/doosan-bobcat/image/upload/v1623442254/Bobcat%20Dealer%20Portal/Service/Product%20Images/S76-B4CD_B4ZY_at42de.jpg" style="width:200px" /></p>\n'
    },
    'mymachine_AddSerialControl': {
      'unauthorizedAccessRegion': 'Can\'t access [firt region] serial in [second region] business unit',
      'changeSerialHeading': 'Change Serial number',
      'addButton': 'Add',
      'wrongSerialNumberFormat': 'Wrong serial number format',
      'unauthorizedAccess': 'You cannot access this serial number',
      'unauthorizedAccessProduct': 'User is not authorized to service the [product name] product.',
      'invalidBusinessUnit': 'Business Unit is invalid ',
      'cancelButton': 'Cancel',
      'loadingModalTitle': 'Loading',
      'errorModalTitle': 'Error',
      'unauthorizedAccessError': 'User is not authorized to service the [product name] product.',
      'addAsset': 'Non-Serialized Asset?',
      'addASerialNumber': 'Add a serial number',
      'enterSerialHere': 'Enter Serial Here',
      'unauthorizedAccessBusiness': 'User not authorized to access [region] business unit'
    }
  }
}
