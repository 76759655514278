<template>
  <b-collapse id="main-menu-flyout" v-model="showCollapse" class="w-100 d-md-none">
    <div class="py-3">
      <ul class="col-12 list-unstyled font-condensed m-0">
        <template v-for="(item, i) in menuitems">
          <b-nav-item v-if="!item.items"
                      :key="'main-menu-mobile-item_' + i"
                      class="text-uppercase"
                      link-classes="p-3"
                      :data-test-id="'main-menu-mobile-item_' + i"
                      :class="{ 'mt-2' : i > 0 }"
                      :href="item.path"
                      :active="isCurrentPath(item.path)"
                      :disabled="item.disabled"
          >
            <b-icon v-if="item.icon && item.icon !== 'null'" :icon="item.icon" fixed-width />
            <span class="ml-2 text-uppercase">{{ item.title }}</span>
          </b-nav-item>

          <b-nav-text v-else
                      :key="'main-menu-mobile-item_' + i"
                      v-b-toggle:id="'main-menu-mobile-subnav_' + i"
                      class="position-relative w-100 nav-link text-uppercase p-3"
                      tag="li"
                      :data-test-id="'main-menu-mobile-item_' + i"
                      :class="{ 'mt-2' : i > 0, 'active' : isCurrentPath(item.path) }"
          >
            <b-icon v-if="item.icon && item.icon !== 'null'" :icon="item.icon" fixed-width />
            <span class="ml-2 text-uppercase dropdown">
              {{ item.title }}
              <span class="float-right dropdown-toggle" />
            </span>
          </b-nav-text>

          <b-collapse v-if="item.items"
                      :id="'main-menu-mobile-subnav_' + i"
                      :key="'main-menu-mobile-subnav_' + i"
                      tag="ul"
                      class="subnavigation list-unstyled"
                      :data-test-id="'main-menu-mobile-subnav_' + i"
          >
            <b-nav-item v-for="(s, j) in item.items"
                        :key="'main-menu-mobile-subnav_' + i + '_item_' + j"
                        link-classes="py-3 d-flex"
                        :data-test-id="'main-menu-mobile-subnav_' + i + '_item_' + j"
                        :href="s.path"
                        :active="isCurrentPath(s.path, true)"
                        :disabled="s.disabled"
            >
              <b-icon v-if="s.icon && s.icon !== 'null'" :icon="s.icon" :alt="s.title" class="mr-2" fixed-width />
              <b-icon v-else-if="hasIcons(item)" :icon="'cog'" class="invisible mr-2" fixed-width />
              {{ s.title }}
            </b-nav-item>
          </b-collapse>
        </template>
      </ul>
    </div>
  </b-collapse>
</template>

<script>
export default {
  props: {
    menuitems: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      showCollapse: false
    }
  },
  mounted() {
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId === 'profile-flyout' && isJustShown) this.showCollapse = false //close this menu when profile menu is opened
    })
  },
  methods: {
    hasIcons(item) {
      return item.items.filter(x => x.icon && x.icon !== 'null').length
    },
    isCurrentPath(path, exact) {
      if (!path) return false
      const currentPath = window.location.pathname.split('/').filter(Boolean)
      path = path.split('/').filter(Boolean)
      const match = path.every((e, i) => e === currentPath[i])
      return exact ? (currentPath.length === path.length && match) : match
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/colors';
@import '../scss/transitions';

#main-menu-flyout {
  background: $gray-800;

  .nav-link:not(.disabled) {
    transition: $transition-base;
    background: $black;
    color: $white;

    &:active,
    &.active {
      border-bottom: 4px solid $primary;
    }

    &:hover {
      background: $white;
      color: $black;
    }
  }

  .subnavigation {
    .nav-link {
      background: $black;

      &.active,
      &:active {
        border-bottom: none;
      }
    }
  }
}
</style>
