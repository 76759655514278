<template>
  <header id="header" class="position-fixed bg-black w-100">
    <b-navbar toggleable type="dark" class="p-0 align-items-stretch flex-nowrap h-100">
      <b-navbar-brand class="justify-content-center align-items-start px-4 m-0 bg-primary d-none d-md-flex flex-column"
                      data-test-id="header-brand-desktop"
      >
        <Logo height="34" />
        <div class="font-condensed text-uppercase">{{ service }}</div>
      </b-navbar-brand>

      <b-navbar-toggle target="main-menu-flyout" data-test-id="header-nav-toggle" class="d-md-none text-white border-0 p-3">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 5.33333V3.66667C0 3.29846 0.447688 3 1 3H23C23.5523 3 24 3.29846 24 3.66667V5.33333C24 5.70154 23.5523 6 23 6H1C0.447688 6 0 5.70154 0 5.33333ZM1 13.5H23C23.5523 13.5 24 13.2015 24 12.8333V11.1667C24 10.7985 23.5523 10.5 23 10.5H1C0.447688 10.5 0 10.7985 0 11.1667V12.8333C0 13.2015 0.447688 13.5 1 13.5ZM23 21H1C0.447688 21 0 20.7015 0 20.3333V18.6667C0 18.2985 0.447688 18 1 18H23C23.5523 18 24 18.2985 24 18.6667V20.3333C24 20.7015 23.5523 21 23 21Z" fill="currentColor" />
        </svg>
      </b-navbar-toggle>

      <MainMenuDesktop v-if="menuItems" :menuitems="menuItems" />

      <b-navbar-brand class="justify-content-center align-items-start px-4 d-flex d-md-none flex-column"
                      data-test-id="header-brand-mobile"
      >
        <Logo height="24" />
        <div class="text-uppercase">{{ service }}</div>
      </b-navbar-brand>

      <ProfileMenu
        v-if="user.name"
        ref="profile"
        :user="user"
        :logout-label="logoutLabel"
        :current-region-label="currentRegionLabel"
        :regions="regions"
        @logout="logout"
        @change-region="changeRegion"
      >
        <slot name="profile-menu" />
      </ProfileMenu>
    </b-navbar>

    <MainMenuMobile v-if="menuItems" :menuitems="menuItems" />
  </header>
</template>

<script>
import ProfileMenu from '../components/ProfileMenu'
import Logo from './BLogo'
import MainMenuMobile from './MainMenuMobile'
import MainMenuDesktop from './MainMenuDesktop'

export default {
  components: {
    ProfileMenu,
    Logo,
    MainMenuDesktop,
    MainMenuMobile
  },
  props: {
    service: {
      type: String,
      default: null,
      required: false
    },
    logoutLabel: {
      type: String,
      default: 'Logout',
      required: false
    },
    user: {
      type: Object,
      default: null,
      required: true
    },
    menuItems: {
      type: Array,
      default: function () {
        return [
          {
            'icon': 'book',
            'title': 'Software Center',
            'path': '/'
          }
        ]
      },
      required: false
    },
    regions: {
      type: Array,
      default: () => []
    },
    currentRegionLabel: {
      type: String
    }
  },
  computed: {
    username() {
      return this.user ? this.user.name : ''
    }
  },
  methods: {
    logout() {
      this.$emit('logout')
    },
    hideProfile() {
      this.$refs.profile.hide()
    },
    changeRegion(region) {
      this.$emit('change-region', region)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../scss/colors';
@import '../scss/grid';
@import '../scss/z-index';
@import '../scss/transitions';

#header {
  z-index: $zindex-modal-backdrop - 5;
  height: 4rem;

  .navbar-brand {
    transition: $transition-base;

    > div {
      font-size: .625rem;
      margin-left: 1.8rem;
    }

    @include media-breakpoint-up(md) {
      > div {
        font-size: .875rem;
        margin-left: 2.5rem;
      }
    }
  }

  .navbar-toggler {
    outline: none;

    &[aria-expanded="true"] {
      background: $gray-800;
    }
  }

}
</style>
