import axios from 'axios'

const axiosInstance = axios.create()
axiosInstance.CancelToken = axios.CancelToken

export default axiosInstance

axiosInstance.interceptors.response.use(
  response => response,
  (error) => {
    if (error.response) {
      const message = error.response.data.error_message || error.response.data.errorMessage || (error.response.data.error && error.response.data.error.message) || error.toString()
      const code = parseInt(error.response.data.error_code || error.response.data.errorCode || (error.response.data.error && error.response.data.error.code) || error.response.status)
      return Promise.reject({message, code})
    } else {
      return Promise.reject(error)
    }
  }
)
