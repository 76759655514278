<template>
  <div v-if="nodes" class="clearfix">
    <EditableComponent
      v-for="(node, i) in nodes"
      :key="'editable-component__' + i"
      :content="node"
    />
  </div>
</template>

<script>
import {TemplateAnnotations} from '@magnolia/template-annotations'
import EditableComponent from './EditableComponent'
import EditableMixin from './EditableMixin'
import {insertComments} from './helper'
import {inEditorEdit, inLocalhostEditor} from './advanced-helper'

export default {
  name: 'EditableArea',
  components: {
    EditableComponent
  },
  inject: ['config', 'templateAnnotations', 'templateDefinitions', 'pageTemplateId'],
  mixins: [EditableMixin],
  props: {
    content: {
      type: Object
    },
    parentTemplateId: {
      type: String,
      required: false
    },
    elementType: {
      type: String,
      default: 'div'
    },
    customView: {
      type: Object
    }
  },
  computed: {
    nodes() {
      return this.content['@nodes']
        ? this.content['@nodes'].map(nodeName => this.content[nodeName])
        : []
    }
  },
  mounted() {
    /*if (!this.content || !EditorContextHelper.isRenderingAnnotation()) {
      return
    }*/
    if (this.templateAnnotations.value && this.templateAnnotations.value[this.content['@path']]) {
      const commentOpen = this.templateAnnotations.value[this.content['@path']]
      insertComments(this.$el, commentOpen, '/cms:area')
    } else { // FIXED WAY
      const templateDefinition = this.templateDefinitions[this.pageTemplateId]
      if (inEditorEdit || inLocalhostEditor) {
        const commentOpen = TemplateAnnotations.getAreaCommentString(this.content, templateDefinition)
        insertComments(this.$el, commentOpen, '/cms:area')
      }
    }
  }
}
</script>
