<template>
  <aside class="d-none d-md-block bg-gray-100 rounded font-condensed position-relative overflow-hidden pb-lg-4">
    <overlay-wrapper :show="loading" class="h-100">
      <b-nav vertical class="inpage-nav d-none d-lg-flex" data-test-id="inpage-nav-desktop" :class="{ 'py-5' : loading && !items.length }">
        <li v-for="(itm, i) in items"
            :key="'page_navigation_item_' + i"
            class="nav-item text-nowrap"
            :class="{'disabled':itm.disabled}"
        >
          <router-link
            class="nav-link text-wrap"
            :to="{ path: itm.path }"
            :exact="itm.path === '/'"
            exact-path
            :data-test-id="'nav-page-vertical-item_' + i"
            :event="!itm.disabled ? 'click' : ''"
          >
            <b-icon v-if="itm.icon && itm.icon !== 'null'" :icon="itm.icon" class="mr-2" fixed-width />
            <b-icon v-else-if="hasIcons" :icon="'cog'" class="invisible mr-2" fixed-width />
            {{ itm.title }}
          </router-link>
        </li>
      </b-nav>
      <div v-if="disabled"
           class="mask disabled"
      />
    </overlay-wrapper>
  </aside>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: true
    },
    items: {
      type: Array,
      required: true,
      default() { return [] }
    }
  },
  computed: {
    hasIcons() {
      return this.items.filter(x => x.icon && x.icon !== 'null').length
    }
  }
}
</script>

<style lang="scss">
@import '../../scss/transitions';
@import '../../scss/colors';

.inpage-nav {
  .nav-item {
    &:hover:not(.disabled) {
      box-shadow: 0 .5rem 1rem rgba($black, .15);
      background: $white;
      transition: $transition-base;
      z-index: 1;
    }

    &.disabled {
      .nav-link {
        cursor: default;
        color: $gray-600;
      }
    }
  }
  .nav-link {
    padding: 1.1rem 1.5rem;
    color: $black;
    font-size: 1.125rem;
    transition: $transition-base;

    &.router-link-active {
      color: $primary;
      background: $white;
    }
  }
}
</style>
