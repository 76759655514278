const {VUE_APP_OKTAORG_URI,
  NODE_ENV,
  VUE_APP_MODE,
  VUE_APP_AUTHORIZATION_SERVER,
  VUE_APP_OKTA_HOSTED_URI,
  VUE_APP_OKTA_CLIENT_ID,
  VUE_APP_PUBLIC_PATH,
  VUE_APP_ABSOLUTE_PATH,
  VUE_APP_MYMACHINE_PUBLIC_PATH} = process.env

function getInjectedParams() {
  const app = document.getElementsByTagName('body')[0]
  if (app.dataset.config) {
    const cfg = JSON.parse(app.dataset.config)
    return {
      nodeEnv: cfg.nodeEnv != '%NODE_ENV%' ? cfg.nodeEnv : false,
      oktaOrgUrl: cfg.orgUrl != '%OKTA_BASE_URI%' ? cfg.orgUrl : false,
      authorizationServer: cfg.authorizationServer != '%OKTA_AUTHORIZATION_SERVER%' ? cfg.authorizationServer : false,
      oktaHostedUrl: cfg.oktaHostedUrl != '%OKTA_HOSTED_URI%' ? cfg.oktaHostedUrl : false,
      clientId: cfg.clientId != '%OKTA_CLIENT_ID%' ? cfg.clientId : false,
      absolutePath: cfg.absolutePath != '%ABSOLUTE_PATH%' ? cfg.absolutePath : false
    }
  }
  return {}
}

const injectedParams = getInjectedParams()

export default {
  nodeEnv: injectedParams.nodeEnv || VUE_APP_MODE || NODE_ENV,
  oktaOrgUrl: injectedParams.oktaOrgUrl || VUE_APP_OKTAORG_URI,
  authorizationServer: injectedParams.authorizationServer || VUE_APP_AUTHORIZATION_SERVER,
  oktaHostedUrl: injectedParams.oktaHostedUrl || VUE_APP_OKTA_HOSTED_URI,
  clientId: injectedParams.clientId || VUE_APP_OKTA_CLIENT_ID,
  publicPath: VUE_APP_PUBLIC_PATH,
  absolutePath: injectedParams.absolutePath || VUE_APP_ABSOLUTE_PATH,
  myMachinePath: VUE_APP_MYMACHINE_PUBLIC_PATH
}
