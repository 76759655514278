import api from '../api'
import {inMgnlIframe} from '@/vue-editor/advanced-helper'
let apiEndpoints = require('../api/apiEndpoints')
const controller = {}

export const magnoliaService = {
  fetchPage,
  fetchPageNav,
  fetchCategory,
  fetchTemplateDefinition
}

function fetchPage(path, region, lang) {
  if (typeof controller['fetchPage'] !== 'undefined') controller['fetchPage'].abort()
  controller['fetchPage'] = new AbortController()
  const regionLowerCase = region.toLowerCase()
  let urlString = (inMgnlIframe ? (window.location.origin + '/' + apiEndpoints.MAGNOLIA_AUTHOR.PAGE) : ('https:' + api.endpoints.MAGNOLIA_AUTHOR.PAGE)).replace('{page_path}', `owner-portal/${regionLowerCase}/${path}`)
  let url = new URL(urlString)
  url.search = new URLSearchParams({lang})
  return fetch(url, {signal: controller['fetchPage'].signal}).then(response => response.json().then(data => { return {data}}))
}

function fetchPageNav(region, lang) {
  if (typeof controller['fetchPageNav'] !== 'undefined') controller['fetchPageNav'].abort()
  controller['fetchPageNav'] = new AbortController()
  const regionLowerCase = region.toLowerCase()
  const urlString = (inMgnlIframe ? (window.location.origin + '/' + apiEndpoints.MAGNOLIA_AUTHOR.PAGENAV) : ('https:' + api.endpoints.MAGNOLIA_AUTHOR.PAGENAV)).replace('{region}', regionLowerCase)
  let url = new URL(urlString)
  url.search = new URLSearchParams({lang})

  return fetch(url, {signal: controller['fetchPageNav'].signal}).then(response => response.json().then(data => { return {data}}))
}

function fetchCategory(category) {
  const urlString = (inMgnlIframe ? (window.location.origin + '/' + apiEndpoints.MAGNOLIA_AUTHOR.CATEGORY) : ('https:' + api.endpoints.MAGNOLIA_AUTHOR.CATEGORY)).replace('{category}', category)
  let url = new URL(urlString)
  return fetch(url).then(response => response.json().then(data => { return {data}}))
}

function fetchTemplateDefinition(template) {
  const urlString = inMgnlIframe ? (window.location.origin + '/' + apiEndpoints.MAGNOLIA_AUTHOR.TEMPLATE_DEFINITIONS + template) : ('https:' + api.endpoints.MAGNOLIA_AUTHOR.TEMPLATE_DEFINITIONS + template)
  let url = new URL(urlString)
  return fetch(url).then(response => response.json().then(data => { return {data}}))
}
